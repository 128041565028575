import React, { useContext, useEffect, useState } from 'react';
import FuelConsumptionDataTable from '../../FuelConsumptions/components/tables/FuelConsumptionDataTable';
import { FuelConsumption, FuelConsumptionFilters } from '../../../app/models/FuelConsumption';
import useFuelConsumptionService from '../../../app/services/hooks/useFuelConsumptionService';
import moment from 'moment';
import { toast } from 'react-toastify';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { AppContext } from '../../../contexts/AppContext';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';


interface Props {
    truckId: number;
}

const TruckFuelConsumptionContainer = ({truckId}: Props) => {

        const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);

    const [fuelConsumptions, setFuelConsumptions] = useState<FuelConsumption[]>([]);
    const [totalRows, setTotalRows] = useState<number>(0);
    const [fuelConsumptionFilter, setfuelConsumptionFilter] = useState<FuelConsumptionFilters>({
            page: 1,
            per_page: 10,
            sort: 'id',
            order: 'desc',
            charge_date_from: moment().date(1).format('YYYY-MM-DD'),
            charge_date_until: moment().format('YYYY-MM-DD')
    });
    
    const { fetchingGetFuelConsumptionsByTruck, getFuelConsumptionsByTruck, getExcelAllFuelConsumptionsByTruck } = useFuelConsumptionService();

    useEffect(() => {
        reloadTable();
    }, [
        fuelConsumptionFilter.page, 
        fuelConsumptionFilter.per_page, 
        fuelConsumptionFilter.sort, 
        fuelConsumptionFilter.order
    ]);

    const getAllFuelConsumptions = () => {
        getFuelConsumptionsByTruck(fuelConsumptionFilter, truckId, {
            onSuccess: (response: ServiceResponse) => {
                setFuelConsumptions(response.data.fuel_consumptions);
                setTotalRows(response.data.total_rows);
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message, {
                    autoClose: 2000
                });
            }
        });
    };

    const reloadTable = () => {
        getAllFuelConsumptions();
    };

    const downloadExcel = () => {
        if (showLoading) showLoading('download', 'Recuperando documento...');
        getExcelAllFuelConsumptionsByTruck(fuelConsumptionFilter, truckId, {
            onSuccess: (message: string) => {
                if (changeAnimation) changeAnimation('downloadSuccess', message, true);
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            }
        });
    };

    return (
        <div>
            <FuelConsumptionDataTable
                fuelConsumptions={fuelConsumptions}
                loading={fetchingGetFuelConsumptionsByTruck}
                filter={fuelConsumptionFilter}
                setFilter={setfuelConsumptionFilter}
                totalRows={totalRows}
                downloadExcel={downloadExcel}
                onSearch={reloadTable}
            />
        </div>
    )
}

export default TruckFuelConsumptionContainer
