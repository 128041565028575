import React, { useContext, useEffect, useState } from 'react';

import { toast } from 'react-toastify';

import { Carrier } from '../../../app/models/Carrier';
import { Truck } from '../../../app/models/Truck';
import { Hopper } from '../../../app/models/Hopper';
import { Unit } from '../../../app/models/Unit';
import { Product } from '../../../app/models/Product';
import { Place } from '../../../app/models/Place';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import LazyLoading from '../../../components/LazyLoading';
import GuideForm from './forms/GuideForm';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { defaultGuideDataForm, GuideDataForm } from '../../../app/models/Guide';
import useGuideService from '../../../app/services/hooks/useGuideService';
import { AppContext } from '../../../contexts/AppContext';

interface Props {
    guideId: number;
    transportOrderId: number;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const GuideEdit = ({ transportOrderId, guideId, onSaved, onCancel, onError }: Props) => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const { fetchingEditGuide, editGuide, fetchingUpdateGuide, updateGuide } = useGuideService();

    const [guide, setGuide] = useState<GuideDataForm>({
        ...defaultGuideDataForm,
        file: undefined,
        transport_order_id: transportOrderId
    });

    const [carriers, setCarriers] = useState<Carrier[]>([]);
    const [trucks, setTrucks] = useState<Truck[]>([]);
    const [hoppers, setHoppers] = useState<Hopper[]>([]);
    const [units, setUnits] = useState<Unit[]>([]);
    const [products, setProducts] = useState<Product[]>([]);
    const [origins, setOrigins] = useState<Place[]>([]);
    const [destinies, setDestinies] = useState<Place[]>([]);

    const [errorFields, setErrorFields] = useState<any>();

    useEffect(() => {
        edit();
    }, [guideId]);

    const edit = () => {
        if (showLoading) showLoading('loading', 'Cargando guia...');
        editGuide(transportOrderId, guideId, {
            onSuccess: (response: ServiceResponse) => {
                setGuide({
                    ...guide,
                    ...response.data.guide
                });
                setCarriers(response.data.carriers);
                setTrucks(response.data.trucks);
                setHoppers(response.data.hoppers);
                setUnits(response.data.units);
                setProducts(response.data.products);
                setOrigins(response.data.origins);
                setDestinies(response.data.destinies);
                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    };

    useEffect(() => {
        if (guide.repeat_folio_number) {
            update();
        }
    }, [guide.repeat_folio_number]);
    

    const update = () => {
        if (showLoading) showLoading('loading', 'Actualizando guia...');
        updateGuide(transportOrderId, guideId, guide, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            },
            onWarning: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().requestConfirmation({
                    title: 'Atención',
                    message: response.message,
                    buttons: {
                        confirmButton: {
                            label: 'Si, modificar',
                            onClick: () => {
                                setGuide({
                                    ...guide,
                                    repeat_folio_number: true
                                });
                                return;
                            }
                        },
                        cancelButton: {
                            label: 'No, cancelar',
                            onClick: () => {
                                if (onSaved) onSaved();
                            }
                        }
                    }
                });
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return fetchingEditGuide ? (
        <LazyLoading height={300} />
    ) : (
        <>
            <div className="row">
                <div className="col-12">
                    <GuideForm
                        guide={guide}
                        setGuide={setGuide}
                        carriers={carriers}
                        trucks={trucks}
                        hoppers={hoppers}
                        units={units}
                        products={products}
                        origins={origins}
                        destinies={destinies}
                        errorFields={errorFields}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm callbackCancel={cancel} locked={fetchingUpdateGuide} />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={update} locked={fetchingUpdateGuide} />
                </div>
            </div>
        </>
    );
};

export default GuideEdit;
