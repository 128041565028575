import { useState } from 'react';
import useFetch from '../../../hooks/useFetch';
import { ServiceEvents } from '../shared/interfaces';
import { EndPoints } from '../EndPoints';
import { SocietyDocument } from '../../models/Society';
import { DocumentNameFilters } from '../../models/Document';

const useSocietyDocumentService = () => {
    const { doGet, doPost, doPut } = useFetch();

    const [fetchingGetSocietyDocuments, setFetchingGetSocietyDocuments] = useState(false);
    const [fetchingStoreSocietyDocument, setFetchingStoreSocietyDocument] = useState(false);
    const [
        fetchingGetSocietyDocumentHistoryBySocietyAndDocumentType,
        setFetchingGetSocietyDocumentHistoryBySocietyAndDocumentType
    ] = useState(false);
    const [fetchingEditSocietyDocument, setFetchingEditSocietyDocument] = useState(false);
    const [fetchingUpdateSocietyDocument, setFetchingUpdateSocietyDocument] = useState(false);

    const getSocietyDocumentsBySociety = (society_id: number, filters: DocumentNameFilters, events: ServiceEvents = {}) => {
        const queryString = Object.entries(filters)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');
        const url = EndPoints.SOCIETY_DOCUMENTS.GET_SOCIETY_DOCUMENTS_BY_SOCIETY.replace(
            ':id',
            society_id.toString()
        );
        doGet({
            ...events,
            url: `${url}?${queryString}`,
            setFetching: setFetchingGetSocietyDocuments
        });
    };

    const storeSocietyDocument = (societyDocument: SocietyDocument, events: ServiceEvents = {}) => {
        doPost({
            ...events,
            url: EndPoints.SOCIETY_DOCUMENTS.STORE_SOCIETY_DOCUMENT,
            requestType: 'multipart',
            body: societyDocument,
            setFetching: setFetchingStoreSocietyDocument
        });
    };

    const getSocietyDocumentHistoryBySocietyAndDocumentType = (
        society_id: number,
        document_id: number,
        events: ServiceEvents = {}
    ) => {
        const url = `${EndPoints.SOCIETY_DOCUMENTS.HISTORY_BY_SOCIETY_AND_TYPE}`;
        doGet({
            ...events,
            url: url
                .replaceAll(':society_id', society_id.toString())
                .replaceAll(':document_id', document_id.toString()),
            setFetching: setFetchingGetSocietyDocumentHistoryBySocietyAndDocumentType
        });
    };

        const editSocietyDocument = (
            society_id: number,
            document_id: number,
            events: ServiceEvents = {}
        ) => {
            const url = `${EndPoints.SOCIETY_DOCUMENTS.EDIT_SOCIETY_DOCUMENT}`;
            doGet({
                ...events,
                url: url
                    .replaceAll(':society_id', society_id.toString())
                    .replaceAll(':document_id', document_id.toString()),
                setFetching: setFetchingEditSocietyDocument
            });
        };
    
        const updateSocietyDocument = (
            society_document_id: number,
            societyDocument: SocietyDocument,
            events: ServiceEvents = {}
        ) => {
            const url = `${EndPoints.SOCIETY_DOCUMENTS.UPDATE_SOCIETY_DOCUMENT}`;
            doPut({
                ...events,
                url: url
                    .replaceAll(':society_document_id', society_document_id.toString()),
                body: societyDocument,
                setFetching: setFetchingUpdateSocietyDocument
            });
        };

    return {
        fetchingGetSocietyDocumentHistoryBySocietyAndDocumentType,
        fetchingGetSocietyDocuments,
        fetchingStoreSocietyDocument,
        fetchingEditSocietyDocument,
        fetchingUpdateSocietyDocument,
        getSocietyDocumentHistoryBySocietyAndDocumentType,
        getSocietyDocumentsBySociety,
        storeSocietyDocument,
        editSocietyDocument,
        updateSocietyDocument
    };
};

export default useSocietyDocumentService;
