import React, { useContext, useEffect, useState } from 'react';
import Breadcrumbs, { BreadcrumbsItem } from '../../template/MainTheme/components/Breadcrumbs';
import DefaultCard from '../../components/default/DefaultCard';
import ButtonCreate from '../../components/buttons/ButtonCreate';
import useTransportationOrderService from '../../app/services/hooks/useTransportationOrderService';
import {
    TransportationOrderDataForm,
    TransportationOrderFilters,
    TransportationOrderStatusRequestDataForm
} from '../../app/models/TransportationOrder';
import moment from 'moment';
import { ServiceResponse } from '../../app/services/shared/interfaces';
import DefaultModal from '../../components/default/DefaultModal';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import TransportationOrderCreate from './components/TransportationOrderCreate';
import { AuthContext } from '../../contexts/AuthContext';
import { Roles } from '../../app/shared/enums';
import TransportationOrderDataTable from './components/tables/TransportationOrderDataTable';
import useNavigationPage from '../../hooks/useNavigationPage';
import { AppContext } from '../../contexts/AppContext';
import useReactConfirmAlert from '../../hooks/useReactConfirmAlert';

const breadcrumbs: BreadcrumbsItem[] = [
    {
        name: 'Pedidos de Transporte',
        url: '/transportation-orders',
        isActive: true
    }
];

const TransportationOrder = () => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const history = useHistory();
    const {
        fetchingGetTransportationOrders,
        getTransportationOrders,
        deleteTransportationOrder,
        getExcelAllTransportationOrders
    } = useTransportationOrderService();

    const { auth } = useContext(AuthContext);
    const { navigationPage } = useNavigationPage();

    const [transportationOrders, setTransportationOrders] = useState<TransportationOrderDataForm[]>(
        []
    );
    const [statuses, setStatuses] = useState<TransportationOrderStatusRequestDataForm[]>([]);
    const [showingCreate, setShowingCreate] = useState(false);
    const [totalRows, setTotalRows] = useState<number>(0);

    const [filter, setFilter] = useState<TransportationOrderFilters>({
        page: 1,
        per_page: 10,
        sort: 'id',
        order: 'desc'
    });

    useEffect(() => {
        reloadTable();
    }, [filter.page, filter.per_page, filter.sort, filter.order]);

    const reloadTable = () => {
        getTransportationOrders(filter, {
            onSuccess: (response: ServiceResponse) => {
                setTransportationOrders(response.data.transportation_orders);
                setStatuses(response.data.transportations_order_statuses);
                setTotalRows(response.data.total_rows);
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message, {
                    autoClose: 2500
                });
                if (response.data.to_dashboard) {
                    navigationPage('/');
                }
            }
        });
    };

    const showCreate = () => {
        setShowingCreate(true);
    };

    const hideCreate = () => {
        setShowingCreate(false);
    };
    const downloadExcel = () => {
        getExcelAllTransportationOrders(filter);
    };
    const edit = (id?: number) => {
        navigationPage('/transportation-orders/' + id + '/edit');
    };

    const show = (id?: number) => {
        navigationPage('/transportation-orders/' + id + '/show');
    };

    const destroy = (transportationOrderId: number) => {
        const _text = 'Está a punto de eliminar el pedido de transporte #' + transportationOrderId;

        useReactConfirmAlert().requestConfirmation({
            title: '¿Estás seguro?',
            message: _text,
            buttons: {
                confirmButton: {
                    label: 'Si, eliminar',
                    onClick: () => {
                        if (showLoading) showLoading('loading', 'Eliminando pedido...');
                        deleteTransportationOrder(transportationOrderId, {
                            onSuccess: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();

                                useReactConfirmAlert().successAlert({
                                    title: 'Éxito',
                                    message: response.message
                                });

                                reloadTable();
                            },
                            onError: (response: ServiceResponse) => {
                                if (hideLoading) hideLoading();

                                useReactConfirmAlert().errorAlert({
                                    title: 'Error',
                                    message: response.message
                                });
                            }
                        });
                    }
                },
                cancelButton: {
                    label: 'No, cancelar',
                    onClick: () => {
                        setTimeout(() => {
                            useReactConfirmAlert().infoAlert({
                                title: 'Cancelado',
                                message: 'El pedido de transporte no se ha eliminado.'
                            });
                        }, 0);
                    }
                }
            }
        });
    };

    const document = (quoteId: number) => {
        // getDocumentQuote(quoteId, {});
    };

    return (
        <>
            <Breadcrumbs pageSection="Pedidos de Transporte" breadcrumbs={breadcrumbs} />
            <DefaultCard>
                <div className="row">
                    <div className="col-12">
                        <div className="row">
                            <div className="col">
                                {auth?.roles && !auth?.roles.includes(Roles.RUNNER) && (
                                    <ButtonCreate
                                        callbackCreate={showCreate}
                                        title="Nuevo Pedido de Transporte"
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <TransportationOrderDataTable
                            transportationOrders={transportationOrders}
                            totalRows={totalRows}
                            loading={fetchingGetTransportationOrders}
                            destroy={destroy}
                            edit={/*showEdit*/ edit}
                            filter={filter}
                            setFilter={setFilter}
                            show={show}
                            downloadExcel={downloadExcel}
                            onSearch={reloadTable}
                        />
                    </div>
                </div>
            </DefaultCard>
            {showingCreate ? (
                <DefaultModal
                    show={showingCreate}
                    handleClose={hideCreate}
                    title="Crear Pedido de Transporte"
                    backdrop={true}
                    showFooter={false}
                >
                    <TransportationOrderCreate
                        // onSaved={() => {
                        //      reloadTable();
                        //     hideCreate();
                        // }}
                        onCancel={hideCreate}
                    />
                </DefaultModal>
            ) : null}
        </>
    );
};

export default TransportationOrder;
