import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import useSweetAlert from '../../../hooks/useSweetAlert';
import Breadcrumbs, { BreadcrumbsItem } from '../../../template/MainTheme/components/Breadcrumbs';
import { Tab, Tabs } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { Society } from '../../../app/models/Society';
import { Provider } from '../../../app/models/Provider';
import DefaultCard from '../../../components/default/DefaultCard';
import DefaultModal from '../../../components/default/DefaultModal';
import LazyLoading from '../../../components/LazyLoading';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import { Brand } from '../../../app/models/Brand';
import { VehicleModel } from '../../../app/models/VehicleModel';
import { HopperDataForm, HopperDocument } from '../../../app/models/Hopper';
import useHopperService from '../../../app/services/hooks/useHopperService';
import { AppContext } from '../../../contexts/AppContext';
import { AuthContext } from '../../../contexts/AuthContext';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import HopperForm from '../components/forms/HopperForm';
import HopperDocumentCreate from '../components/HopperDocumentCreate';
import HopperDocumentHistory from '../components/HopperDocumentHistory';
import { DocumentSet } from '../../../app/models/DocumentSet';
import useHopperDocumentService from '../../../app/services/hooks/useHopperDocumentService';
import DocumentDataTable from '../../Documents/components/tables/DocumentDataTable';
import DocumentsDataTable from '../../../components/DocumentsDataTable';
import { DocumentNameFilters } from '../../../app/models/Document';
import HopperDocumentEdit from '../components/HopperDocumentEdit';

type RouterParams = {
    id: string;
};

const HopperEdit = () => {
    const history = useHistory();
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const { id } = useParams<RouterParams>();
    const { auth } = useContext(AuthContext);
    
    const [verifyingHopper, setVerifyingHopper] = useState<boolean>(true);
    const { getHopperDocumentsByHopper, getHopperDocumentBySet, fetchingGetHopperDocuments } = useHopperDocumentService();
    const [societies, setSocieties] = useState<Society[]>([]);
    const [providers, setProviders] = useState<Provider[]>([]);
    const [brands, setBrands] = useState<Brand[]>([]);
    const [vehicleModels, setVehicleModels] = useState<VehicleModel[]>([]);
    const [documentSets, setDocumentSets] = useState<DocumentSet[]>([]);
    const [hopper, setHopper] = useState<HopperDataForm>({ ['is_internal']: undefined });
    const [copyHopper, setCopyHopper] = useState<HopperDataForm>();
    const [documentTypes, setDocumentTypes] = useState<any[]>([]);
    const [showingCreateDocument, setShowingCreateDocument] = useState(false);
    const [showingHistoryDocument, setShowingHistoryDocument] = useState(false);
    const [showingEditDocument, setShowingEditDocument] = useState(false);
    const [typeDocumentSelected, setTypeDocumentSelected] = useState<any>();
    const [errorFields, setErrorFields] = useState<any>();
    const [hopperDocuments, setHopperDocuments] = useState<HopperDocument[]>([]);
    const [filter, setFilter] = useState<DocumentNameFilters>({
        document_name: ''
    })
    const { editHopper, updateHopper, fetchingEditHopper, fetchingUpdateHopper } =
        useHopperService();

    useEffect(() => {
        edit(parseInt(id));
    }, [id]);

    useEffect(() => {
        getDocuments(parseInt(id));
    }, []);

    const reloadTable = () => {
        getDocuments(parseInt(id));
    }

    useEffect(() => {
        if (hopper?.is_internal == copyHopper?.is_internal) {
            if (hopper?.is_internal == true) {
                setHopper({
                    ...hopper,
                    society_id: copyHopper?.society_id,
                    provider_id: undefined,
                    is_internal: true
                });
            } else if (hopper?.is_internal == false) {
                setHopper({
                    ...hopper,
                    provider_id: copyHopper?.provider_id,
                    society_id: undefined,
                    is_internal: false
                });
            }
        } else {
            if (hopper?.is_internal == true) {
                setHopper({
                    ...hopper,
                    society_id: 0,
                    provider_id: undefined,
                    is_internal: true
                });
            } else if (hopper?.is_internal == false) {
                setHopper({
                    ...hopper,
                    provider_id: 0,
                    society_id: undefined,
                    is_internal: false
                });
            }
        }
    }, [hopper?.is_internal]);

    const edit = (id: number) => {
        editHopper(id, {
            onSuccess: (response: ServiceResponse) => {
                setSocieties(response.data.societies);
                setProviders(response.data.providers);
                setBrands(response.data.brands);
                setVehicleModels(response.data.vehicle_models);
                setCopyHopper(response.data.hopper);
                setDocumentSets(response.data.document_sets);
                setHopper(response.data.hopper);
                setVerifyingHopper(false);
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message);
                if (response.data.to_dashboard) {
                    history.goBack();
                }
            }
        });
        getDocuments(id);
    };

    const update = () => {
        if (hopper) {
            if (showLoading) showLoading('loading', 'Actualizando...');
            updateHopper(Number(hopper?.id), hopper, {
                onSuccess: (response: ServiceResponse) => {
                    if (hideLoading) hideLoading();
                    useReactConfirmAlert().successAlert({
                        title: 'Éxito',
                        message: response.message
                    });
                    toast.success(response.message, {
                        autoClose: 2500
                    });
                    setErrorFields(undefined);
                    edit(parseInt(id));
                },
                onError: (response: ServiceResponse) => {
                    if (hideLoading) hideLoading();
                    useReactConfirmAlert().errorAlert({
                        title: 'Error',
                        message: response.message
                    });
                },
                onFieldError: (errorFields: ServiceResponse) => {
                    if (hideLoading) hideLoading();

                    setErrorFields(errorFields.data);
                }
            });
        }
    };

    const getDocuments = (id: number) => {
        getHopperDocumentsByHopper(id, filter, {
            onSuccess: (response: ServiceResponse) => {
                setDocumentTypes(response.data.hopper_documents);
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message);
                if (response.data.to_dashboard) {
                    history.goBack();
                }
            }
        });
    };

    const handleGetDocumentSet = (documentSetId: number): void => {
        getHopperDocumentBySet(parseInt(id), documentSetId);
    };

    const showCreateDocument = (type: string) => {
        setTypeDocumentSelected(type);
        setShowingCreateDocument(true);
    };

    const hideCreateDocument = () => {
        setShowingCreateDocument(false);
    };

    const showHistoryDocument = (type: string) => {
        setTypeDocumentSelected(type);
        setShowingHistoryDocument(true);
    };

    const hideHistoryDocument = () => {
        setShowingHistoryDocument(false);
    };

    const showEditDocument = (type: string) => {
        setTypeDocumentSelected(type);
        setShowingEditDocument(true);
    };

    const hideEditDocument = () => {
        setShowingEditDocument(false);
    };

    const breadcrumbs: BreadcrumbsItem[] = [
        {
            name: 'Semi Remolque',
            url: '/hoppers',
            isActive: false
        },
        {
            name: 'Editar #' + id,
            url: '/hoppers/' + id + '/edit',
            isActive: true
        }
    ];

    if (fetchingEditHopper) {
        return (
            <DefaultCard>
                <LazyLoading height={300} />
            </DefaultCard>
        );
    }

    return (
        <>
            <Breadcrumbs pageSection={`Editar #${id}`} breadcrumbs={breadcrumbs} />

            {/* Tabs */}

            <Tabs defaultActiveKey="general-data" id="quote-tabs" className="mb-3">
                <Tab eventKey="general-data" title="Datos Generales">
                    <div className="row">
                        <div className="col-md-6">
                            <DefaultCard>
                                <div className="h3 mt-0 mb-2 card-title">Datos Generales</div>
                                {hopper && (
                                    <HopperForm
                                        hopperForm={hopper}
                                        setHopperForm={setHopper}
                                        societies={societies}
                                        providers={providers}
                                        brands={brands}
                                        isEdit={true}
                                        vehicleModels={vehicleModels}
                                        documentSets={documentSets}
                                        errorFields={errorFields}
                                    />
                                )}
                                <div className="row justify-content-end">
                                    <div className="col-auto">
                                        <ButtonSaveForm
                                            callbackSave={update}
                                            locked={fetchingUpdateHopper}
                                        />
                                    </div>
                                </div>
                            </DefaultCard>
                        </div>
                    </div>
                </Tab>

                <Tab eventKey="management" title="Gestión" disabled>
                    Gestión
                </Tab>
            </Tabs>

            {hopper && (
                <div className="row">
                    <div className="col-md-12">
                        <DefaultCard>
                            <div className="h3 mt-0 mb-2 card-title">Documentos</div>
                            <DocumentsDataTable
                                data={documentTypes}
                                create={showCreateDocument}
                                showHistory={showHistoryDocument}
                                edit={auth?.permissions?.includes('change_hopperdocument') ? showEditDocument : undefined}
                                progressPending={fetchingGetHopperDocuments}
                                filter={filter}
                                setFilter={setFilter}
                                onSearch={reloadTable}
                                documentSets={documentSets}
                                getDocumentSet={handleGetDocumentSet}
                            />
                        </DefaultCard>
                    </div>
                </div>
            )}

            {showingCreateDocument ? (
                <DefaultModal
                    show={showingCreateDocument}
                    handleClose={hideCreateDocument}
                    title="Agregar Documento"
                    backdrop={true}
                    showFooter={false}
                >
                    <HopperDocumentCreate
                        onSaved={() => {
                            edit(parseInt(id));
                            hideCreateDocument();
                        }}
                        typeDocument={typeDocumentSelected}
                        hopperId={Number(hopper?.id)}
                        onCancel={hideCreateDocument}
                        onError={hideCreateDocument}
                    />
                </DefaultModal>
            ) : null}

            {showingEditDocument ? (
                <DefaultModal
                    show={showingEditDocument}
                    handleClose={hideEditDocument}
                    title="Editar Fechas del Documento"
                    backdrop={true}
                    showFooter={false}
                >
                    <HopperDocumentEdit
                        onSaved={() => {
                            hideEditDocument()
                            edit(parseInt(id));
                        }}
                        typeDocument={typeDocumentSelected}
                        hopperId={Number(hopper?.id)}
                        onCancel={hideEditDocument}
                        onError={hideEditDocument}
                    />
                </DefaultModal>
            ) : null}

            {showingHistoryDocument ? (
                <DefaultModal
                    show={showingHistoryDocument}
                    handleClose={hideHistoryDocument}
                    title={`Historial de documentos de ${
                        documentTypes?.find(
                            (document_type: any) => document_type.id === typeDocumentSelected
                        ).document_name
                    }`}
                    backdrop={true}
                    size={'lg'}
                    showFooter={false}
                >
                    <HopperDocumentHistory
                        typeDocument={typeDocumentSelected}
                        hopperId={Number(hopper?.id)}
                        onCancel={hideHistoryDocument}
                        onError={hideHistoryDocument}
                    />
                </DefaultModal>
            ) : null}
        </>
    );
};

export default HopperEdit;
