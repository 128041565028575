import React, { useContext, useState, useEffect } from 'react';
import { AppContext } from '../../../contexts/AppContext';
import { WorkerDocument } from '../../../app/models/Workers';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { toast } from 'react-toastify';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import GeneralDocumentForm from '../../../components/GeneralDocumentForm';
import useHopperDocumentService from '../../../app/services/hooks/useHopperDocumentService';
import { HopperDocument, defaultHopperDocument } from '../../../app/models/Hopper';

interface Props {
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
    hopperId: number;
    typeDocument: string;
}

const HopperDocumentEdit = ({ onSaved, onCancel, onError, typeDocument, hopperId }: Props) => {

    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const [hopperDocument, setHopperDocument] = useState<HopperDocument>(defaultHopperDocument);
    const [errorFields, setErrorFields] = useState<any>();
    const { fetchingEditHopperDocument,
        fetchingUpdateHopperDocument,
        editHopperDocument,
        updateHopperDocument } = useHopperDocumentService();

    useEffect(() => {
        edit();
    }, []);

    const edit = () => {
        if (showLoading) showLoading('loading', 'Guardando documento...');
        editHopperDocument(hopperId, parseInt(typeDocument), {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setHopperDocument(response.data.hopper_document)
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    const update = () => {
        if (!hopperDocument) return;
        if (showLoading) showLoading('loading', 'Guardando documento...');
        updateHopperDocument(hopperDocument.id!, hopperDocument, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return (
        <>
            <div className="row">
                <div className="col-12">
                    <GeneralDocumentForm
                        document={hopperDocument}
                        setDocument={setHopperDocument}
                        errorFields={errorFields}
                        edit={true}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm
                        callbackCancel={cancel}
                        locked={fetchingUpdateHopperDocument}
                    />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm 
                        callbackSave={update} 
                        locked={fetchingUpdateHopperDocument} 
                    />
                </div>
            </div>
        </>
    );
};

export default HopperDocumentEdit;
