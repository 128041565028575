import React, { useContext, useEffect, useState } from 'react';
import { Group, GroupDataForm } from '../../../app/models/Group';

import { ServiceResponse } from '../../../app/services/shared/interfaces';
import LazyLoading from '../../../components/LazyLoading';

import { AppContext } from '../../../contexts/AppContext';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { toast } from 'react-toastify';
import useGroupService from '../../../app/services/hooks/useGroupService';
import GroupFormContainer from './GroupFormContainer';
import { ContentType, Category } from '../../../app/models/ContentType';
import { Permission } from '../../../app/models/Permission';

interface Props {
    groupId: number;
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const GroupEdit = ({ groupId, onSaved, onCancel, onError }: Props) => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const { fetchingEditGroup, editGroup, fetchingUpdateGroup, updateGroup } = useGroupService();
    const [contentTypes, setContentTypes] = useState<ContentType[]>([]);
    const [categories, setCategories] = useState<Category[]>([]);
    const [group, setGroup] = useState<GroupDataForm>({ permissions: [], name: '' });
    const [errorFields, setErrorFields] = useState<any>();

    useEffect(() => {
        edit();
    }, []);

    const edit = () => {
        if (showLoading) showLoading('loading', 'Cargando rol...');
        editGroup(groupId, {
            onSuccess: (response: ServiceResponse) => {
                const group_raw = response.data.group;

                group_raw.permissions = response.data.group.permissions.map(
                    (permission: Permission) => Number(permission.id)
                );

                setGroup(group_raw);
                setCategories(response.data.categories);
                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    };

    const update = () => {
        if (showLoading) showLoading('loading', 'Actualizando rol...');
        updateGroup(groupId, group, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();

                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return fetchingEditGroup ? (
        <LazyLoading height="300" />
    ) : (
        <GroupFormContainer
            fetching={fetchingUpdateGroup}
            contentTypes={contentTypes}
            categories={categories}
            action={update}
            cancel={cancel}
            group={group}
            setGroup={setGroup}
            errorFields={errorFields}
        />
    );
};

export default GroupEdit;
