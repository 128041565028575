import React from 'react';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import { GroupDataForm } from '../../../app/models/Group';
import GroupForm from './forms/GroupForm';
import { ContentType, Category } from '../../../app/models/ContentType';

interface Props {
    fetching?: boolean;
    action: () => void;
    cancel: () => void;
    contentTypes: ContentType[];
    categories: Category[];
    group: GroupDataForm;
    setGroup: (group: GroupDataForm) => void;
    errorFields?: any;
}

const GroupFormContainer = ({
    fetching,
    action,
    cancel,
    group,
    setGroup,
    contentTypes,
    categories,
    errorFields = null
}: Props) => {
    return (
        <>
            <div className="row">
                <div className="col-12">
                    <GroupForm
                        groupDataForm={group}
                        setGroupDataForm={setGroup}
                        // contentTypes={contentTypes}
                        categories={categories}
                        errorFields={errorFields}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm callbackCancel={cancel} locked={fetching} />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={action} locked={fetching} />
                </div>
            </div>
        </>
    );
};

export default GroupFormContainer;
