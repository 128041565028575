import React from 'react';
import { Accordion } from 'react-bootstrap';
import { InternalSettlementShowDebitData } from '../../../../app/models/InternalSettlement';

interface Props {
  internalSettlementDebitDataForm: InternalSettlementShowDebitData;
}

const InternalSettlementShowDebitForm = ({ internalSettlementDebitDataForm }: Props) => {
  if (!internalSettlementDebitDataForm) return null;

  return (
    <Accordion>
      <Accordion.Item eventKey={`expense${internalSettlementDebitDataForm.id}`} key={`expense${internalSettlementDebitDataForm.id}`}>
        <Accordion.Header>
          <span className="text-uppercase">Transferencia n°{internalSettlementDebitDataForm.id}</span>
        </Accordion.Header>
        <Accordion.Body>
          <div className="row pt-2">
            <div className="mb-2 row">
                    <div className="col-md-12">
                    <label className="col-form-label">Fecha de emisión</label>
                    <input 
                        type="date"
                        className="form-control"
                        value={internalSettlementDebitDataForm.issue_date} 
                        disabled={true} 
                        />
                    </div>
            </div>
          </div>
          <div className="row pt-2">
            <div className="mb-2 row">
                    <div className="col-md-12">
                    <label className="col-form-label">Conductor</label>
                    <input
                            name="carrier"
                            id="carrier"
                            className="form-control"
                            value={internalSettlementDebitDataForm.carrier}
                            disabled={true}
                        />
                    </div>
                </div>
          </div>
          <div className="row pt-2">
            <div className="mb-2 row">
                    <div className="col-md-12">
                    <label className="col-form-label">Motivo</label>
                        <input
                                name="transfer_reason"
                                id="transfer_reason"
                                className="form-control"

                                value={internalSettlementDebitDataForm.transfer_reason}
                                disabled={true}
                            />
                    </div>
                </div>
          </div>
            <div className="row pt-2">
            <div className="mb-2 row">
                    <div className="col-md-6">
                        <label className="col-form-label">Banco</label>
                            <input
                                name="bank"
                                id="bank"
                                className="form-control"
                                value={internalSettlementDebitDataForm.bank}
                                disabled={true}                           
                            />
                    </div>
                    <div className="col-md-6">
                        <label className="col-form-label">Tipo de cuenta</label>
                        <input
                            name="account_type"
                            id="account_type"
                            className="form-control"
                            value={internalSettlementDebitDataForm.account_type}
                            disabled={true}        
                        />
                    </div>
                    </div>
            </div>
            <div className="row pt-2">
                <div className="mb-2 row">
                    <div className="col-md-12">
                        <label className="col-form-label">N° de cuenta</label>
                        <input
                        name="account_number"
                        id="account_number"
                        className="form-control"
                        value={internalSettlementDebitDataForm.account_number}
                        disabled={true}
                    />
                    </div>
                </div>
            </div>
            <div className="row pt-2">
                <div className="mb-2 row">
                    <div className="col-md-6">
                        <label className="col-form-label">
                            Monto
                        </label>
                        <input
                            name={'amount'}
                            value={internalSettlementDebitDataForm.amount}
                            className="form-control"
                            placeholder="Ingrese el monto"
                            disabled={true}
                        />

                    </div>
                    <div className="col-md-6">
                        <label className="col-form-label">Moneda</label>
                        <input
                            name="currency"
                            id="currency"
                            className="form-control"
                            value={internalSettlementDebitDataForm.currency}
                            placeholder={'Seleccione moneda'}
                            disabled={true}                                        />
                    </div>
                </div>
            </div>
            <div className="row pt-2">
                <div className="mb-2 row">
                    <div className="col-md-12">
                        <label className="col-form-label">
                        Comprobante
                        </label>
                        {internalSettlementDebitDataForm.file? (
                            <div>
                                <a
                                    href={internalSettlementDebitDataForm.file}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="btn btn-success btn-sm"
                                    role="button"
                                    aria-pressed="true"
                                >
                                    Descargar
                                </a>
                            </div>
                        ) : (
                            <div>
                                <p>No tiene Comprobante</p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className="row pt-2">
                <div className="mb-2 row">
                <div className="col-md-12">
                    <label className="col-form-label">Descripción</label>
                    <textarea
                        name="description"
                        id="description"
                        className="form-control"
                        value={internalSettlementDebitDataForm.description}
                        disabled={true}
                    />
                </div>
            </div>
          </div>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
};

export default InternalSettlementShowDebitForm;
