import React, { useContext, useState, useEffect } from 'react';
import { AppContext } from '../../../contexts/AppContext';
import { WorkerDocument } from '../../../app/models/Workers';
import useWorkerDocumentService from '../../../app/services/hooks/useWorkerDocumentService';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { toast } from 'react-toastify';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import GeneralDocumentForm from '../../../components/GeneralDocumentForm';

interface Props {
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
    workerId: number;
    typeDocument: string;
}

const WorkerDocumentEdit = ({ onSaved, onCancel, onError, typeDocument, workerId }: Props) => {

    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);

    const [workerDocuments, setWorkerDocument] = useState<WorkerDocument>({
        worker_id: workerId,
        is_expire_date_indefinite: false,
        document_id: parseInt(typeDocument)
    });
    const [errorFields, setErrorFields] = useState<any>();
    const { fetchingEditWorkerDocument,
        fetchingUpdateWorkerDocument,
        editWorkerDocument,
        updateWorkerDocument } = useWorkerDocumentService();

    useEffect(() => {
        edit();
    }, []);

    const edit = () => {
        if (!workerDocuments) return;
        if (showLoading) showLoading('loading', 'Guardando documento...');
        editWorkerDocument(workerId, parseInt(typeDocument), {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setWorkerDocument(response.data.worker_document)
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    const update = () => {
        if (!workerDocuments) return;
        if (showLoading) showLoading('loading', 'Guardando documento...');
        updateWorkerDocument(workerDocuments.id!, workerDocuments, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return (
        <>
            <div className="row">
                <div className="col-12">
                    <GeneralDocumentForm
                        document={workerDocuments}
                        setDocument={setWorkerDocument}
                        errorFields={errorFields}
                        edit={true}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm
                        callbackCancel={cancel}
                        locked={fetchingUpdateWorkerDocument}
                    />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm 
                        callbackSave={update} 
                        locked={fetchingUpdateWorkerDocument} 
                    />
                </div>
            </div>
        </>
    );
};

export default WorkerDocumentEdit;
