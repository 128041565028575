import React, { useContext, useEffect, useRef, useState } from 'react';
import { AppContext } from '../../../contexts/AppContext';
import { toast } from 'react-toastify';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import SalesInvoiceFormContainer from './SalesInvoiceFormContainer';
import { PaymentStatus } from '../../../app/models/PaymentStatus';
import { SalesInvoicePaymentDataForm } from '../../../app/models/SalesInvoice';
import useSalesInvoiceService from '../../../app/services/hooks/useSalesInvoiceService';
import { Society } from '../../../app/models/Society';
import { Client } from '../../../app/models/Client';
import useClientService from '../../../app/services/hooks/useClientService';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';

interface Props {
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const SalesInvoiceCreate = ({ onSaved, onCancel, onError }: Props) => {
    const { showLoading, hideLoading } = useContext(AppContext);
    const [salesInvoice, setSalesInvoice] = useState<any>({});
    const [societies, setSocieties] = useState<Society[]>([]);
    const [clients, setClients] = useState<Client[]>([]);
    const societyIdSelected = useRef<number | undefined>(0);
    const clientsFromToi = useRef<Client[]>([]);
    const [paymentStatuses, setPaymentStatuses] = useState<SalesInvoicePaymentDataForm[]>([]);
    const [errorFields, setErrorFields] = useState<any>();

    const { 
        storeSalesInvoice, 
        createSalesInvoice,
        getPaymentStatusesByClientSociety, 
        fetchingGetPaymentStatusesByClientSociety,
        fetchingCreateSalesInvoice
    } = useSalesInvoiceService();
    const { getClientsBySociety, fetchingGetClientBySociety } = useClientService();

    useEffect(() => {
        create();
    }, []);

    const create = () => {
        if (showLoading) showLoading('loading', 'Cargando datos...');
        createSalesInvoice({
            onSuccess: (response: ServiceResponse) => {
                // setPaymentStatuses(response.data.payment_statuses);
                setSocieties(response.data.societies);
                if (hideLoading) hideLoading();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
                if (onError) onError();
            }
        });
    };

    // Fetch clients when society_id changes
    useEffect(() => {
        if (salesInvoice.society_id && salesInvoice.society_id !== societyIdSelected.current) {
            getClientsBySociety(salesInvoice.society_id, {
                onSuccess: (response: ServiceResponse) => {
                    setClients(response.data.clients);
                    societyIdSelected.current = salesInvoice.society_id;
    
                    // Type prev as SalesInvoiceType
                    setSalesInvoice ({
                        ...salesInvoice,
                        client_id: 0, // Reset client selection
                    });
                },
                onError: (response: ServiceResponse) => {
                    toast.error(response.message);
                },
            });
        }
    }, [salesInvoice.society_id]);


    useEffect(() => {
        if (salesInvoice.society_id && salesInvoice.client_id) {
            getPaymentStatusesByClientSociety(salesInvoice.society_id, salesInvoice.client_id, {
                onSuccess: (response: ServiceResponse) => {
                    setPaymentStatuses(response.data.payment_statuses);
                },
                onError: (response: ServiceResponse) => {
                    toast.error(response.message);
                }
            });
        }
    }, [salesInvoice.society_id, salesInvoice.client_id]);

    const store = () => {
        if (showLoading) showLoading('loading', 'Guardando factura de venta...');
        storeSalesInvoice(salesInvoice, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.success(response.message, { autoClose: 2500 });
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message,
                    message_list: response.data?.message_list ?? []
                });
                toast.error(response.message);
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return fetchingCreateSalesInvoice ? (
        <div>Cargando...</div>
    ) : (
        <SalesInvoiceFormContainer
            action={store}
            cancel={cancel}
            societies={societies}
            clients={clients}
            salesInvoice={salesInvoice}
            setSalesInvoice={setSalesInvoice}
            availableStatuses={paymentStatuses}
            errorFields={errorFields}
            updated={false}
            disabled={false}
            loadingClient={fetchingGetClientBySociety}
            loadingPaymentStatus={fetchingGetPaymentStatusesByClientSociety}
        />
    );
};

export default SalesInvoiceCreate;
