import React, { useEffect, useMemo, useState } from 'react';
import useHandleErrorFields from '../../../../hooks/useHandleErrorFields';
import { GroupDataForm } from '../../../../app/models/Group';
import ToggleBox from '../../../../components/ToggleBox';
import { ContentType, Category } from '../../../../app/models/ContentType';
import { Accordion, Tab, Tabs } from 'react-bootstrap';


type Props = {
    groupDataForm: GroupDataForm;
    categories: Category[];
    setGroupDataForm: (groupDataForm: GroupDataForm) => void;
    errorFields?: any;
};

const GroupForm = ({ groupDataForm, setGroupDataForm, errorFields, categories }: Props) => {
    const { fieldErrorMessage, fieldHasError, onFocusRemove, setErrors } = useHandleErrorFields();
    const [searchTerm, setSearchTerm] = useState('');
    const [contentTypes, setContentTypes] = useState<ContentType[] | undefined>(categories[0]?.content_types);

    const filterGroups = (contentType: ContentType) => {
        return contentType.name.toLowerCase().includes(searchTerm.toLowerCase());
    };

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value);
    };

    useEffect(() => {
        if (categories && categories[0]?.content_types) setContentTypes(categories[0].content_types)
    }, [categories]);

    const filteredContentTypes = useMemo(() => {
        if (!contentTypes) return [];
        return contentTypes.filter(filterGroups);
    }, [contentTypes, searchTerm]);

    useEffect(() => {
        setErrors(errorFields);
    }, [errorFields]);
    
    const isSelectedPermission = (id: number) => {
        return !!groupDataForm.permissions.find((s) => s === id);
    };

    const setSelectedPermissions = async (id: number) => {
        const selection = groupDataForm.permissions.find((s) => s === id);

        if (!selection) {
            setGroupDataForm({
                ...groupDataForm,
                permissions: [...groupDataForm.permissions, id]
            });
        } else {
            setGroupDataForm({
                ...groupDataForm,
                permissions: groupDataForm.permissions.filter((s) => s !== id)
            });
        }
    };
    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>
    ) => {
        const { name, value } = e.target;
        setGroupDataForm({ ...groupDataForm, [name]: value });
    };

    const handleTabSelect = (selectedTabName: string | null) => {
        if (!selectedTabName) return;
        
        const selectedCategory = categories.find((category) => category.name === selectedTabName);
        
        if (selectedCategory?.content_types) {
            setContentTypes(selectedCategory.content_types);
        }
    };

    return (
        <form
            onSubmit={(e) => {
                e.preventDefault();
                e.stopPropagation();
            }}
        >
            <div className="row">
                <div className="mb-2 row needs-validation">
                    <label className="col-md-12 col-form-label">Nombre</label>
                    <div className="col-md-12">
                        <input
                            name="name"
                            id="name"
                            type="text"
                            className={`form-control ${fieldHasError('name')}`}
                            value={groupDataForm.name}
                            onChange={handleChange}
                            onFocus={() => onFocusRemove('name')}
                            placeholder="Ingrese el nombre del rol"
                        />
                        <div className="invalid-feedback" style={{ display: 'flex' }}>
                            {fieldErrorMessage('name')}
                        </div>
                    </div>
                </div>

                <div className="col-md-12">
                    <Tabs defaultActiveKey={categories[0]?.name} id="quote-tabs" onSelect={handleTabSelect} className="mb-3">
                        { categories.length != 0 ? (
                            categories.map((category, index) => {
                                return (
                                    <Tab eventKey={category.name} title={category.name} key={index}>
                                        <label htmlFor="name" className="form-label">
                                            Permisos
                                        </label>
                                        <input
                                            className="form-control"
                                            type="text"
                                            placeholder="Buscar grupos de permisos"
                                            value={searchTerm}
                                            onChange={handleSearchChange}
                                        />
                                        <Accordion>
                                            {category?.content_types && category?.content_types?.length != 0 ? (
                                                filteredContentTypes.length === 0 ? (
                                                    <div className="mt-2 text-danger fw-bold">
                                                        No se encontraron coincidencias
                                                    </div>
                                                ) : (
                                                    filteredContentTypes.map((group, index) => {
                                                        return (
                                                            <Accordion.Item
                                                                onFocus={() => onFocusRemove('permissions')}
                                                                eventKey={`group${index}`}
                                                                key={`group${index}`}
                                                            >
                                                                <Accordion.Header>
                                                                    <span className="text-uppercase">{group.name}</span>
                                                                </Accordion.Header>
                                                                <Accordion.Body>
                                                                    <div className="row pt-2">
                                                                        {group.permission_set &&
                                                                            group.permission_set.map(
                                                                                (permission, index) => {
                                                                                    return (
                                                                                        <ToggleBox
                                                                                            key={`permission${index}`}
                                                                                            onClick={() =>
                                                                                                setSelectedPermissions(
                                                                                                    permission.id
                                                                                                )
                                                                                            }
                                                                                            isSelected={isSelectedPermission(
                                                                                                permission.id
                                                                                            )}
                                                                                            title=""
                                                                                            name={String(
                                                                                                permission.description ??
                                                                                                    ''
                                                                                            )}
                                                                                        />
                                                                                    );
                                                                                }
                                                                            )}
                                                                    </div>
                                                                </Accordion.Body>
                                                            </Accordion.Item>
                                                        );}
                                                    ))
                                                ) : (
                                                    <div className="mt-2 text-danger fw-bold">
                                                        No hay permisos disponibles
                                                    </div>
                                            )}
                                        </Accordion>
                                    </Tab>
                                )
                            })
                        ) : (
                            <div className="mt-2 text-danger fw-bold">
                                No hay permisos disponibles
                            </div>
                        )}
                    </Tabs>
                </div>
            </div>
        </form>
    );
};

export default GroupForm;
