import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Breadcrumbs, { BreadcrumbsItem } from '../../../template/MainTheme/components/Breadcrumbs';
import useDispatchProgramService from '../../../app/services/hooks/useDispatchProgramService';
import {
    defaultDispatchProgramShowDataForm,
    DispatchProgramShowDataForm
} from '../../../app/models/DispatchProgram';
import DefaultCard from '../../../components/default/DefaultCard';
import LazyLoading from '../../../components/LazyLoading';
import DefaultCreatedModifiedUserForm from '../../../components/default/DefaultCreatedModifiedUserForm';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { RouterParams } from '../../../app/shared/interfaces';
import DispatchProgramShowForm from '../components/forms/DispatchProgramShowForm';
import DispatchProgramItemsContainer from '../components/DispatchProgramItemsContainer';
import { TransportationOrderStatusRequestDataForm } from '../../../app/models/TransportationOrder';

const DispatchProgramShow = () => {
    const { id } = useParams<RouterParams>();

    const { showDispatchProgram, fetchingShowDispatchProgram } = useDispatchProgramService();

    const [transportationOrder, setTransportationOrder] =
        useState<TransportationOrderStatusRequestDataForm>({
            id: 0,
            name: ''
        });
    const [transportationSubOrderItemId, setTransportationSubOrderItemId] = useState<number>(0);
    const [dispatchProgramShowDataForm, setDispatchProgramShowDataForm] =
        useState<DispatchProgramShowDataForm>({
            id: 0,
            contact_email: '',
            expired_at: '',
            client: '',
            quote_id: 0,
            quote: undefined,
            payment_method: '',
            payment_condition: '',
            currency: '',
            society: '',
            status: '',
            status_selected: undefined,
            status_choices: [],
            created_at: '',
            created_user: '',
            updated_at: '',
            last_modified_user: '',
            reference: '',
            days_count: 0
        });

    useEffect(() => {
        reloadModule();
    }, []);

    const reloadModule = () => {
        show(parseInt(id));
        // show(parseInt(id));
    };

    const show = (id: number) => {
        showDispatchProgram(id, {
            onSuccess: (response: ServiceResponse) => {
                setTransportationSubOrderItemId(
                    response.data.dispatch_program.transportation_order_item_id
                );
                setTransportationOrder(response.data.transportation_order);
                setDispatchProgramShowDataForm(response.data.dispatch_program);
            }
        });
    };

    const breadcrumbs: BreadcrumbsItem[] = [
        {
            name: 'Despachos',
            url: '/dispatch-programs',
            isActive: false
        },
        {
            name: 'Ver despacho #' + id,
            url: '/dispatch-programs/' + id + '/show',
            isActive: true
        }
    ];

    return (
        <>
            <Breadcrumbs pageSection={`Mostrar despacho #${id}`} breadcrumbs={breadcrumbs} />

            <div className="row">
                <div className="col-md-6">
                    <DefaultCard>
                        <div className="h3 mt-0 mb-2 card-title">Datos Generales</div>
                        {!fetchingShowDispatchProgram ? (
                            <>
                                <DispatchProgramShowForm
                                    dispatchProgramShowDataForm={dispatchProgramShowDataForm}
                                    transportationOrder={transportationOrder}
                                />
                            </>
                        ) : (
                            <LazyLoading />
                        )}
                    </DefaultCard>
                </div>
                <div className="col-md-6">
                    <div className="card">
                        <div className="card-body">
                            <div className="h3 mt-0 mb-2 card-title">Usuarios</div>
                            {!fetchingShowDispatchProgram ? (
                                <DefaultCreatedModifiedUserForm
                                    createdBy={dispatchProgramShowDataForm.created_user}
                                    createdAt={dispatchProgramShowDataForm.created_at}
                                    updatedBy={dispatchProgramShowDataForm.last_modified_user}
                                    updatedAt={dispatchProgramShowDataForm.updated_at}
                                    // status={dispatchProgramShowDataForm.status}
                                    status={dispatchProgramShowDataForm.status_selected?.name.toString()}

                                    // status={
                                    //     dispatchProgramShowDataForm.status_selected
                                    //         ? dispatchProgramShowDataForm.status_selected.name.toString()
                                    //         : ''
                                    // }
                                />
                            ) : (
                                <LazyLoading />
                            )}
                        </div>
                    </div>
                </div>
            </div>
            {!fetchingShowDispatchProgram ? (
                <>
                    <DispatchProgramItemsContainer
                        dispatchProgramId={parseInt(id)}
                        transportationSubOrderItemId={transportationSubOrderItemId}
                        canBeEdited={false}
                        isShow={true}
                    />
                </>
            ) : null}
        </>
    );
};

export default DispatchProgramShow;
