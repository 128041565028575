import { Society } from './Society';
import { Provider } from './Provider';
import { Brand } from './Brand';
import { VehicleModel } from './VehicleModel';
import { DocumentSet } from './DocumentSet';
import { Status } from './Status';

export interface Truck {
    id?: number;
    patent?: string;
    transport_company_id?: number;
    hoppers_assigned?: [];
    society_id?: number;
    provider_id?: number;
    brand_id?: number;
    vehicle_model_id?: number;
    vehicle_model?: VehicleModel;
    status?: string;
    status_selected?: Status;
    brand?: Brand;
    provider?: Provider;
    society?: Society;
    documents?: TruckDocument[];
    created_at?: string;
    updated_at?: string;
    deleted_at?: string;

    is_external?: boolean;
}

export interface TruckDataForm {
    id?: number;
    user_id?: number;
    patent?: string;
    society_id?: number;
    provider_id?: number;
    provider?: string;
    society?: string;
    brand_id?: number;
    vehicle_model_id?: number;
    status?: string;
    brand?: Brand;
    vehicle_model?: VehicleModel;
    document_sets?: DocumentSet[];
    year?: number;
    vin?: string;
    is_active?: boolean;

    is_internal?: boolean;
}

export interface TruckFilters {
    truck_id?: number | null;
    provider?: string | null;
    society?: string | null;
    patent?: string | null;
    brand?: string | null;
    owner?: string | null;
    vehicle_model?: string | null;
    status?: string | null;
    year?: string | null;
    vin?: string | null;
    page: number;
    per_page: number;
    sort: string;
    order: string;
}

export const defaultTruckDataForm: TruckDataForm = {
    society_id: 0,
    provider_id: undefined,
    brand_id: 0,
    vehicle_model_id: 0,
    status: 'OPERATIVO',
    is_internal: true,
    year: 2000,
    vin: ''
};

export interface TruckDocument {
    id?: number;
    name?: string;
    file?: File;
    issue_date?: string;
    expiration_date?: string;
    type?: string;
    is_expire_date_indefinite: boolean;
    document_id?: number;
    truck_id: number;
}

export const defaultTruckDocument: TruckDocument = {
    id: 0,
    is_expire_date_indefinite: false,
    truck_id: 0
};