import React, { useContext, useEffect, useState } from 'react';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { toast } from 'react-toastify';
import LazyLoading from '../../../components/LazyLoading';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import { AppContext } from '../../../contexts/AppContext';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { useHistory } from 'react-router-dom';
import useNavigationPage from '../../../hooks/useNavigationPage';
import { defaultSurveyDataForm, SurveyDataForm } from '../../../app/models/Survey';
import useSurveyService from '../../../app/services/hooks/useSurveyService';
import SurveyForm from './forms/SurveyForm';
import { EventTypeListItem } from '../../../app/models/EventType';
import { JobTitle } from '../../../app/models/JobTitle';

interface Props {
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
}

const SurveyCreate = ({ onSaved, onCancel, onError }: Props) => {
    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const history = useHistory();
    const { navigationPage } = useNavigationPage();

    const [survey, setSurvey] = useState<SurveyDataForm>(defaultSurveyDataForm);
    const [eventTypes, setEventTypes] = useState<EventTypeListItem[]>([])
    const [jobTitles, setJobTitles] = useState<JobTitle[]>([]);
    const [errorFields, setErrorFields] = useState<any>();

    const { fetchingCreateSurvey, fetchingStoreSurvey, createSurvey, storeSurvey } = useSurveyService();

    useEffect(() => {
        if (showLoading) showLoading('loading', 'Cargando datos...');

        createSurvey({
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setEventTypes(response.data.event_types);
                setJobTitles(response.data.job_titles);
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                toast.error(response.message);
            }
        });
    }, []);

    const store = () => {

        if (showLoading) showLoading('loading', 'Guardando encuesta...');

        storeSurvey(survey, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });

                navigationPage('/surveys/' + response.data.survey_id + '/edit');

                toast.success(response.message, {
                    autoClose: 2500
                });
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return fetchingCreateSurvey ? (
        <LazyLoading height={300} />
    ) : (
        <>
            <div className="row">
                <div className="col-12">
                    <SurveyForm
                        surveyForm={survey}
                        setSurveyForm={setSurvey}
                        errorFields={errorFields}
                        eventTypes={eventTypes}
                        job_titles={jobTitles}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm callbackCancel={cancel} locked={fetchingStoreSurvey} />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm callbackSave={store} locked={fetchingStoreSurvey} />
                </div>
            </div>
        </>
    );
};

export default SurveyCreate;
