import React from 'react';
import useDispatchProgramService from '../../../app/services/hooks/useDispatchProgramService';
import useSweetAlert from '../../../hooks/useSweetAlert';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { toast } from 'react-toastify';
import { DispatchProgramStatusRequestDataForm } from '../../../app/models/DispatchProgram';

interface Props {
    dispatchProgramId: number;
    statuses: DispatchProgramStatusRequestDataForm[];
    selectedStatus: DispatchProgramStatusRequestDataForm;
    callbackSuccess?: () => void;
}

const DispatchProgramChangeStatus = ({
    dispatchProgramId,
    statuses,
    selectedStatus,
    callbackSuccess
}: Props) => {

    const [selected, setSelected] = React.useState(selectedStatus);

    const { 
        preparationStatusDispatchProgram,
        planificationStatusDispatchProgram,
        dispatchStatusDispatchProgram,
        finishedStatusDispatchProgram
    } = useDispatchProgramService();

    const eventChangeStatus = {
        onSuccess: (response: ServiceResponse) => {
            if (callbackSuccess) {
                callbackSuccess();
            }
            useSweetAlert().successAlert({
                title: 'Éxito',
                text: 'Se ha cambiado el estado del despacho #' + dispatchProgramId
            });
        },
        onError: (response: ServiceResponse) => {
            if (callbackSuccess) {
                callbackSuccess();
            }
            toast.error(response.message);
        }
    };

    const changeStatus = (value: string) => {
        if (value == selected.id) return null;
    
        const _status = statuses.find((status: DispatchProgramStatusRequestDataForm) => status.id == value);
    
        setSelected(_status!);
    
        const _text =
            'Está a punto de cambiar el estado de la cotización #' +
            dispatchProgramId +
            ' a ' +
            (_status ? _status.name : '');
    
        useSweetAlert().requestConfirmation({
            title: '¿Está seguro?',
            text: _text,
            confirmButtonText: 'Si, cambiar estado',
            cancelButtonText: 'Cancelar',
            onConfirm: () => {
                switch (value) {
                    case 'PREPARACION':
                        preparationStatusDispatchProgram(dispatchProgramId, eventChangeStatus);
                        break;
                    case 'PLANIFICACION':
                        planificationStatusDispatchProgram(dispatchProgramId, eventChangeStatus);
                        break;
                    case 'DESPACHO':
                        dispatchStatusDispatchProgram(dispatchProgramId, eventChangeStatus);
                        break;
                    case 'FINALIZADO':
                        finishedStatusDispatchProgram(dispatchProgramId, eventChangeStatus);
                        break;
                    default:
                        useSweetAlert().errorAlert({
                            title: 'Error',
                            text: 'Opción no encontrada.'
                        });
                        setSelected(selectedStatus);
                        break;
                }
            },
            onCancel: () => {
                useSweetAlert().infoAlert({
                    title: 'Cancelado',
                    text: 'El estado de la cotización no se ha cambiado.'
                });
                setSelected(selectedStatus);
            }
        });
    };

    if (!selectedStatus) return null;

    return (
        <select
            className="form-control"
            value={selected.id}
            onChange={(e) => changeStatus(e.target.value)}
        >
            <option value={selectedStatus.id}>{selectedStatus.name}</option>
            {statuses.map((status) => {
                return (
                    <option key={status.id} value={status.id}>
                        {status.name}
                    </option>
                );
            })}
        </select>
    );
};

export default DispatchProgramChangeStatus;
