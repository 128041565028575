import { useContext, useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import SalesInvoiceDataTable from './tables/SalesInvoiceDataTable';
import { SalesInvoiceDataForm, SalesInvoiceFilters, SalesInvoicePaymentDataForm } from '../../../app/models/SalesInvoice';
import useSalesInvoiceDetailService from '../../../app/services/hooks/useSalesInvoiceDetailService';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import SalesInvoiceDetailDataTable from './tables/SalesInvoiceDetailDataTable';
import { SalesInvoiceDetail, SalesInvoiceDetailFilters } from '../../../app/models/SalesInvoiceDetail';
import { PaymentStatusDataForm, PaymentStatusesStatus, PaymentStatusFilters } from '../../../app/models/PaymentStatus';
import PaymentStatusDataTable from '../../PaymentStatus/components/tables/PaymentStatusDataTable';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { AppContext } from '../../../contexts/AppContext';
import useSweetAlert from '../../../hooks/useSweetAlert';
import DefaultCard from '../../../components/default/DefaultCard';
import DefaultModal from '../../../components/default/DefaultModal';
import SalesInvoicesEdit from './SalesInvoiceEdit';
import DefaultToolBar from '../../../components/default/DefaultToolBar';
import ButtonCreate from '../../../components/buttons/ButtonCreate';
import useSalesInvoiceHistoryService from '../../../app/services/hooks/useSalesInvoiceHistoryService';
import { SalesInvoiceHistoryDataForm, SalesInvoiceHistoryFilters } from '../../../app/models/SalesInvoiceHistory';
import useSalesInvoiceService from '../../../app/services/hooks/useSalesInvoiceService';
import SalesInvoiceDetailForm from './forms/SalesInvoiceDetailForm';
import { SalesInvoiceStatus } from '../../../app/models/SalesInvoiceStatus';
import SalesInvoiceTotal from './SalesInvoiceTotal';
import DefaultCreatedModifiedUserForm from '../../../components/default/DefaultCreatedModifiedUserForm';
import SalesInvoiceCondition from './SalesInvoiceCondition';
import { SalesInvoiceStatusEnum } from '../../../app/shared/enums';
import LazyLoading from '../../../components/LazyLoading';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';

interface Props {
    salesInvoiceId: number;
    isShow?: boolean;
    salesInvoice: SalesInvoiceDataForm;
    setSalesInvoice?: (salesInvoice: SalesInvoiceDataForm) => void;
    salesInvoiceForm: SalesInvoiceDataForm;
    setSalesInvoiceForm: (salesInvoiceForm: SalesInvoiceDataForm) => void;
    setSalesInvoiceDetailTable?: (salesInvoiceDetail: SalesInvoiceDetail[]) => void;
    reloadSalesInvoiceDetailTable: () => void;
    filterDetail: SalesInvoiceDetailFilters;
    setFilterDetail: (filter: SalesInvoiceDetailFilters) => void;
    reloadSalesInvoice: (salesInvoiceId: number) => void;
    setSalesInvoiceHistoryTable: (salesInvoiceHistory: SalesInvoiceHistoryDataForm[]) => void;
    filterHistory: SalesInvoiceHistoryFilters;
    setFilterHistory: (filter: SalesInvoiceHistoryFilters) => void;
    reloadHistoryTable: () => void;
    reloadDetail:() => void;
    paymentStatuses: PaymentStatusDataForm[];
    setPaymentStatuses?: (paymentStatusesStatus: PaymentStatusDataForm[]) => void;
    paymentStatusesStatus: PaymentStatusesStatus[];
    setPaymentStatusesStatus?: (setPaymentStatusesStatus: PaymentStatusesStatus[]) => void;
    statuses:SalesInvoiceStatus[];
    setStatuses: (statuses: SalesInvoiceStatus[]) => void;
    totalRowsDetail: number
    

}

const SalesInvoiceContainer = ({
    salesInvoiceId,
    salesInvoiceForm,
    setSalesInvoiceDetailTable,
    reloadSalesInvoiceDetailTable,
    setSalesInvoiceHistoryTable,
    filterHistory,
    setFilterHistory,
    filterDetail,
    setFilterDetail,
    reloadHistoryTable,
    paymentStatuses,
    statuses,
    paymentStatusesStatus,
    totalRowsDetail,
    setPaymentStatusesStatus,
    setStatuses,
    reloadDetail,
    reloadSalesInvoice,
    setSalesInvoiceForm,
    isShow = false,
}: Props) => {

    const [salesInvoicesDetail, setSalesInvoicesDetail] = useState<SalesInvoiceDetail[]>([]);
    // const [paymentStatuses, setPaymentStatuses] = useState<PaymentStatusDataForm[]>([]);
    // const [statuses, setStatuses] = useState<PaymentStatusesStatus[]>([]);


    // const [filterHistory, setFilterHistory] = useState<SalesInvoiceHistoryFilters>({
    //     page: 1,
    //     per_page: 10,
    //     sort: 'id',
    //     order: 'desc',
    // });

    // const [totalRows, setTotalRows] = useState<number>(0);
    const [showingEdit, setShowingEdit] = useState(false);
    // const [salesInvoice, setSalesInvoice] = useState<SalesInvoiceDataForm>({});
    const [salesInvoiceDetailIdEdit, setsalesInvoiceDetailIdEdit] = useState<number>(-1);
    const [salesInvoiceHistories, setSalesInvoiceHistories] = useState<SalesInvoiceHistoryDataForm[]>([]);
    // const [salesInvoiceStatus, setSalesInvoiceStatus] = useState<SalesInvoiceDataForm | null>(null); // Cambiar a SalesInvoiceDataForm | null
    const [errorFields, setErrorFields] = useState<any>();

    const [showingShow, setShowingShow] = useState(false);
    const { showLoading, hideLoading } = useContext(AppContext);

    const { getSalesInvoiceDetailsBySalesInvoice, 
            deleteSalesInvoiceDetail,
            fetchingGetSalesInvoiceDetailsBySalesInvoice,
            fetchingDeleteSalesInvoiceDetailsBySalesInvoice } = useSalesInvoiceDetailService();
    const { getAllSalesInvoiceHistoriesByInvoice, fetchingGetSalesInvoiceHistoriesByInvoice } = useSalesInvoiceHistoryService();

    const { editSalesInvoice, updateSalesInvoice, showSalesInvoice, fetchingEditSalesInvoice } = useSalesInvoiceService();

    // useEffect(() => {
    //     reloadSalesInvoiceDetailTable();
    // }, [filterDetail]);


    const update = () => {
        if (showLoading) showLoading('loading', 'Actualizando estado de pago...');
        updateSalesInvoice(salesInvoiceId, salesInvoiceForm, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(undefined);
                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });
                reloadSalesInvoiceDetailTable();
                reloadDetail();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
                toast.error(response.message);
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        }, true); // Pasa `true` como cuarto argumento
    };


    const showEdit = (salesInvoiceDetailId: number) => {
        setShowingEdit(true);
        setsalesInvoiceDetailIdEdit(salesInvoiceDetailId);
    };

    const hideEdit = () => {
        setShowingEdit(false);
        setsalesInvoiceDetailIdEdit(-1);
    };


    const hideShow = () => {
        setShowingShow(false);
    };


    const destroy = (paymentStatusId: number | null) => {
        const _text = paymentStatusId
            ? `Está a punto de desvincular el estado de pago # ${paymentStatusId} de la factura de venta`
            : 'No hay un estado de pago válido para desvincular.';
    
        useSweetAlert().requestConfirmation({
            title: '¿Está seguro?',
            text: _text,
            confirmButtonText: 'Sí, desvincular',
            cancelButtonText: 'Cancelar',
            onConfirm: () => {
                if (paymentStatusId && showLoading) {
                    showLoading('loading', 'Eliminando estado de pago...');
                    deleteSalesInvoiceDetail(salesInvoiceId, paymentStatusId, {
                        onSuccess: (response: ServiceResponse) => {
                            if (hideLoading) hideLoading();
                            useReactConfirmAlert().successAlert({
                                title: 'Éxito',
                                message: response.message,
                            });
                            reloadSalesInvoiceDetailTable();
                            reloadDetail();
                            reloadHistoryTable();
                        },
                        onError: (response: ServiceResponse) => {
                            if (hideLoading) hideLoading();
                            useReactConfirmAlert().errorAlert({
                                title: 'Error',
                                message: response.message,
                            });
                        },
                    });
                } else {
                    useSweetAlert().infoAlert({
                        title: 'Cancelado',
                        text: 'El estado de pago no se ha desvinculado.',
                    });
                }
            },
            onCancel: () => {
                useSweetAlert().infoAlert({
                    title: 'Cancelado',
                    text: 'El estado de pago no se ha desvinculado.',
                });
            }
        });
    };

    return (
        <>

                <div className="row">
                        {/* Datos Generales */}
                        <div className="col-md-6">
                            {/* <DefaultCard>
                                <div className="h3 mt-0 mb-2 card-title">Datos Generales</div>
                                <SalesInvoiceDetailForm salesInvoice={salesInvoiceForm} />
                            </DefaultCard> */}

                        <DefaultCard>
                            <div className="h3 mt-0 mb-2 card-title">Datos Generales</div>
                            {!fetchingEditSalesInvoice ? (
                                <SalesInvoiceDetailForm
                                    salesInvoice={salesInvoiceForm}
                                    setSalesInvoice={setSalesInvoiceForm}
                                    errorFields={errorFields}
                                    disabled={salesInvoiceForm.status !== 'BORRADOR'} // Pasa `true` si no es BORRADOR
                                />
                            ) : (
                                <LazyLoading />
                            )}
                            <hr />
                            <div className="row justify-content-end">
                                {!fetchingEditSalesInvoice && salesInvoiceForm.status === 'BORRADOR' && (
                                    <div className="col-auto">
                                        <ButtonSaveForm callbackSave={update} locked={fetchingEditSalesInvoice} />
                                    </div>
                                )}
                            </div>
                        </DefaultCard>
                        </div>

                        {/* Usuarios */}
                        <div className="col-md-6">
                            <div className="card">
                            <div className="card-body">
                            <div className="h3 mt-0 mb-2 card-title">Usuarios</div>
                                {!fetchingEditSalesInvoice ? (
                                    <DefaultCreatedModifiedUserForm
                                        createdBy={salesInvoiceForm.created_user}
                                        createdAt={salesInvoiceForm.created_at}
                                        updatedBy={salesInvoiceForm.last_modified_user}
                                        updatedAt={salesInvoiceForm.updated_at}
                                        status={salesInvoiceForm.status_label}
                                    />
                                ) : (
                                    <div>Cargando...</div>
                                )}
                            </div>
                            </div>
                        </div>
                    </div>

                    <DefaultCard>

                    <div className="col-12">
                        <SalesInvoiceCondition
                        salesInvoice={salesInvoiceForm}
                        />
                    </div>

                    </DefaultCard>
            
            <DefaultCard>
                <div className="row">
                    <div className="col-12">
                    <DefaultToolBar
                            left={
                                salesInvoiceForm && 
                                (salesInvoiceForm.status === SalesInvoiceStatusEnum.BORRADOR) ? (   
                                    <ButtonCreate callbackCreate={() => showEdit(salesInvoiceDetailIdEdit)} title='Agregar Documento de factura' />
                                ) : null
                            }
                        />
                    </div>
                    {/*  */}
                    {/* se comenta por cambio en como se vera las posiciones de la factura de venta */}
                    {/* <div className="col-12">
                        <PaymentStatusDataTable
                            paymentStatuses={paymentStatuses}
                            totalRows={totalRowsDetail}
                            loading={fetchingGetSalesInvoiceDetailsBySalesInvoice}
                            // destroy={destroy}
                            destroy={salesInvoiceForm.status !== 'CONTABILIZADA' ? destroy : undefined}
                            filter={filterDetail}
                            setFilter={setFilterDetail}
                            statuses={paymentStatusesStatus}
                            callbackSuccess={reloadSalesInvoiceDetailTable}
                        />
                    </div> */}
                    <div className="col-12">
                        <SalesInvoiceDetailDataTable
                            salesInvoiceDetail={paymentStatuses}
                            totalRows={totalRowsDetail}
                            loading={fetchingGetSalesInvoiceDetailsBySalesInvoice}
                            // destroy={destroy}
                            destroy={salesInvoiceForm.status !== SalesInvoiceStatusEnum.CONTABILIZADA ? destroy : undefined}
                            filter={filterDetail}
                            setFilter={setFilterDetail}
                            statuses={paymentStatusesStatus}
                            callbackSuccess={reloadSalesInvoiceDetailTable}
                        />
                    </div>
                    <div className="col-12">
                        <SalesInvoiceTotal
                        salesInvoice={salesInvoiceForm}
                        />
                    </div>
                </div>
            </DefaultCard>
    
            {showingEdit ? (
                <DefaultModal
                    show={showingEdit}
                    handleClose={hideEdit}
                    title="Agregar Documento de Factura"
                    backdrop={true}
                    showFooter={false}
                >
                    <SalesInvoicesEdit
                        salesInvoiceId={Number(salesInvoiceId)}
                        onSaved={() => {
                            reloadHistoryTable();
                            reloadSalesInvoiceDetailTable();
                            reloadDetail();
                            hideEdit();
                        }}
                        onError={() => {
                            reloadSalesInvoiceDetailTable();
                            reloadDetail();
                            hideEdit();
                        }}
                        onCancel={hideEdit}
                        salesInvoiceDetailId={salesInvoiceDetailIdEdit}
                        setSalesInvoiceDetailTable={setSalesInvoiceDetailTable!}
                        reloadSalesInvoiceTable={reloadSalesInvoiceDetailTable}
                        setSalesInvoiceHistoryTable={setSalesInvoiceHistoryTable!}
                        // reloadSalesInvoiceHistoryTable={reloadHistoryTable}
                    />
                </DefaultModal>
            ) : null}

        </>
    );
};

export default SalesInvoiceContainer;
