import { TruckDataForm, TruckFilters } from '../../../../app/models/Truck';
import ButtonTableAction from '../../../../components/buttons/ButtonTableAction';
import DefaultDataTable from '../../../../components/default/DefaultDataTable';
import ActiveControl from '../../../../components/ActiveControl';
import { HopperDataForm } from '../../../../app/models/Hopper';

import { momentParseDate } from '../../../../helpers';

import { formatNumber } from '../../../../utils/utils';
import { ExpenseDataForm, ExpenseFilters } from '../../../../app/models/Expense';
import ExpenseFilter from './ExpenseFilter';
import { Link } from 'react-router-dom';

import circleRed from '../../../../assets/images/icons/circle-color-red.svg';
import circleGreen from '../../../../assets/images/icons/circle-color-green.svg';
import circleGrey from '../../../../assets/images/icons/circle-color-grey.svg';
import { useCallback, useContext, useMemo, useState } from 'react';
import { AuthContext } from '../../../../contexts/AuthContext';
import { AppContext } from '../../../../contexts/AppContext';
import {
    MassiveApproveForm,
    defaultMassiveApproveForm,
    tollApproveOptions
} from '../../../../app/models/Toll';
import useReactConfirmAlert from '../../../../hooks/useReactConfirmAlert';
import { StatusRows } from '../../../../app/shared/interfaces';
import { ServiceResponse } from '../../../../app/services/shared/interfaces';
import ChangeStatusRows from '../../../../components/ChangeStatusRows';
import useExpenseService from '../../../../app/services/hooks/useExpenseService';
import PreviewImageTable from '../../../../components/PreviewImageTable';
import { useScreenDetector } from '../../../../hooks/useScreenDetector';
import { ExpenseType } from '../../../../app/models/ExpenseType';

// const conditionalRowStyles = [
//     {
//         when: (row:ExpenseDataForm) => row.is_approved === true,
//         style: {
//             color: 'rgba(85, 255, 152, 0.79)',
//             fontWeight: 'bold',
//             '&:hover': {
//                 cursor: 'pointer',
//             },
//         },
//
//     },
//     {
//         when: (row:ExpenseDataForm) => row.is_approved === false,
//         style: {
//
//             color: 'rgba(219, 5, 148, 0.79)',
//             fontWeight: 'bold',
//             '&:hover': {
//                 cursor: 'pointer',
//             },
//         },
//
//     },
// ]

interface Props {
    transportOrderId?: number;
    expenses: ExpenseDataForm[];
    expensesType: ExpenseType[];
    totalRows: number;
    callbackSuccess?: () => void;
    loading?: boolean;
    edit?: (id: number) => void;
    show?: (id: number) => void;
    destroy?: (id: number) => void;
    approved?: (id: number, value: boolean, message: string) => void;
    cancel?: (id: number, value: boolean) => void;
    filter: ExpenseFilters;
    setFilter: (filter: ExpenseFilters) => void;
    expensePage?: boolean;
    paginationRowsPerPageOptions?: any[];
    documentExpense: (id: number) => void;
    showExpenseHistory?: (expenseId: number) => void;
    canBeEdited?: boolean;
    onSearch: () => void;
    downloadExcel?: () => void
}

const ExpenseDataTable = ({
    transportOrderId = 0,
    expenses,
    totalRows,
    callbackSuccess,
    loading = false,
    edit,
    destroy,
    approved,
    cancel,
    show,
    filter,
    setFilter,
    expensePage = false,
    documentExpense,
    canBeEdited = false,
    showExpenseHistory,
    onSearch,
    downloadExcel,
    expensesType,
}: Props) => {
    const { auth } = useContext(AuthContext);
    const [expenseUrlFile, setExpenseUrlFile] = useState<string | undefined>(undefined);

    const columns = [
        {
            name: 'Id',
            selector: (row: any) => row?.id,
            sortable: true,
            sortField: 'id',
            cell: (row: ExpenseDataForm, index: any, column: any) => (
                <span className="">
                    <img
                        src={
                            row.is_approved == null
                                ? circleGrey
                                : !row.is_approved
                                ? circleRed
                                : circleGreen
                        }
                        alt="circle"
                        style={{
                            width: '25px',
                            height: '25px',
                            marginRight: '5px',
                            marginBottom: '3px'
                        }}
                    />
                    <span className="">{row.id}</span>
                </span>
            )
        },
        {
            name: 'Orden de transporte',
            selector: (row: any) => row?.transport_order_id,
            sortable: true,
            sortField: 'transport_order_id',
            omit: !expensePage,
            cell: (row: ExpenseDataForm, index: any, column: any) => (
                <span className="">
                    {row.transport_order_id ? (
                        expensePage ? (
                            <Link to={`/transport-orders/${row.transport_order_id}/edit`}>
                                #{row.transport_order_id}
                            </Link>
                        ) : (
                            row.transport_order_id
                        )
                    ) : (
                        ''
                    )}
                </span>
            )
        },
        {
            name: 'Conductor',
            selector: (row: any) => row?.carrier,
            sortable: true,
            sortField: 'carrier',
            cell: (row: ExpenseDataForm, index: any, column: any) => (
                <span
                    className=""
                    dangerouslySetInnerHTML={{ __html: row.carrier ? row.carrier : '' }}
                ></span>
            )
        },

        {
            name: 'Tipo de gasto',
            selector: (row: any) => row?.expense_type,
            sortable: true,
            sortField: 'expense_type',
            cell: (row: ExpenseDataForm, index: any, column: any) => (
                <span className="">{row.expense_type}</span>
            )
        },

        {
            name: 'Valor',
            selector: (row: any) => row?.amount,
            sortable: true,
            sortField: 'amount',
            cell: (row: ExpenseDataForm, index: any, column: any) => (
                <>
                    <span className="">
                        {formatNumber(row.amount ? Number(row.amount) : 0)} {row.currency?.name}
                    </span>
                </>
            )
        },
        {
            name: 'Folio',
            selector: (row: any) => row?.folio,
            sortable: true,
            sortField: 'folio',
            omit: expensePage,
            cell: (row: ExpenseDataForm, index: any, column: any) => (
                <>
                    <span className="">
                        {row.folio}
                    </span>
                </>
            )
        },
        {
            name: 'Proveedor',
            selector: (row: any) => row?.provider,
            sortable: true,
            sortField: 'provider',
            cell: (row: ExpenseDataForm, index: any, column: any) => (
                <span
                    className=""
                    dangerouslySetInnerHTML={{ __html: row.provider ? row.provider : '' }}
                ></span>
            )
        },
        {
            name: 'Fecha de emisión',
            selector: (row: any) => row?.issue_date,
            sortable: true,
            sortField: 'issue_date',
            cell: (row: ExpenseDataForm, index: any, column: any) => (
                <span className="">{momentParseDate(row?.issue_date)}</span>
            )
        },
        {
            name: 'Tipo de documento',
            selector: (row: any) => row?.expense_document_type,
            sortable: true,
            sortField: 'expense_document_type',
            cell: (row: ExpenseDataForm, index: any, column: any) => (
                <span className="">{row?.expense_document_type}</span>
            )
        },

        {
            name: 'Acciones',
            selector: (row: any) => row?.id,
            sortable: false,
            width: '150px',
            cell: (row: ExpenseDataForm, index: any, column: any) => (
                <div className="">
                    {documentExpense && (
                        <ButtonTableAction
                            callbackFunction={() => documentExpense(row?.id ?? -1)}
                            classIcon={'mdi mdi-download'}
                            colorIcon={row.photo ? 'text-success' : 'text-secondary'}
                            locked={!row.photo}
                            errorMessage={'No se puede descargar este documento.'}
                            title={'Descargar documento'}
                        />
                    )}
                    {show && (
                        <ButtonTableAction
                            callbackFunction={() => show(row?.id ?? -1)}
                            classIcon={'mdi mdi-eye'}
                            colorIcon={'text-info'}
                            errorMessage={'No se puede mostrar este registro.'}
                            title={'Ver'}
                        />
                    )}
                    {edit && (
                        <ButtonTableAction
                            callbackFunction={() => edit(row?.id ?? -1)}
                            classIcon={'mdi mdi-pencil'}
                            colorIcon={'text-warning'}
                            errorMessage={'No se puede editar este registro.'}
                            title={'Editar'}
                        />
                    )}
                    {destroy && (row.is_approved === false || row.is_approved === null) && (
                        <ButtonTableAction
                            callbackFunction={() => destroy(row?.id ?? -1)}
                            classIcon={'mdi mdi-delete'}
                            colorIcon={'text-danger'}
                            errorMessage={'No se puede eliminar este registro.'}
                            title={'Eliminar'}
                        />
                    )}
                    {approved &&
                        (row.is_approved === false || row.is_approved === null) &&
                        auth &&
                        auth?.roles &&
                        !auth?.roles.includes('Conductor') && (
                            <>
                                <ButtonTableAction
                                    callbackFunction={() =>
                                        approved(row?.id ?? -1, true, 'aprobado')
                                    }
                                    classIcon={'mdi mdi-checkbox-marked-circle'}
                                    colorIcon={'text-info'}
                                    errorMessage={'No se puede aprobar este registro.'}
                                    title={'Aprobar'}
                                />
                                <ButtonTableAction
                                    callbackFunction={() =>
                                        approved(row?.id ?? -1, false, 'rechazado')
                                    }
                                    classIcon={'mdi mdi-close-circle'}
                                    colorIcon={'text-danger'}
                                    errorMessage={'No se puede rechazar este registro.'}
                                    title={'Rechazar'}
                                />
                            </>
                        )}
                    {cancel && (row.is_approved === false || row.is_approved === true) && (
                        <ButtonTableAction
                            callbackFunction={() => cancel(row?.id ?? -1, row.is_approved ?? false)}
                            classIcon={'fa fa-ban'}
                            colorIcon={'text-danger'}
                            errorMessage={'No se puede anular este registro.'}
                            title={'Anular'}
                        />
                    )}
                    {showExpenseHistory &&
                        auth &&
                        auth?.roles &&
                        !auth?.roles.includes('Conductor') && (
                            <ButtonTableAction
                                callbackFunction={() => showExpenseHistory(row?.id ?? -1)}
                                classIcon={'mdi mdi-history'}
                                colorIcon={'text-info'}
                                errorMessage={'No se puede mostrar el historial de este registro.'}
                                title={'Mostrar historial'}
                            />
                        )}
                </div>
            )
        }
    ];

    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);
    const [rows, setRows] = useState<any[]>([]);
    const [toggleCleared, setToggleCleared] = useState<boolean>(false);
    const [massiveApproveForm, setMassiveApproveFormForm] = useState<MassiveApproveForm>({
        ...defaultMassiveApproveForm,
        transport_order_id: transportOrderId,
        is_toll_expense: false
    });

    const { changeApproveRowsFromTransportOrder, fetchingChangeApproveRowsFromTransportOrder } =
        useExpenseService();

    const contextMessage = ({
        selectedCount,
        allSelected
    }: {
        selectedCount: number;
        allSelected: boolean;
    }) => {
        return {
            singular: 'fila seleccionada',
            plural: 'filas seleccionadas'
        };
    };

    const resetContextActions = () => {
        setToggleCleared(!toggleCleared);
        setRows([]);
        setMassiveApproveFormForm({
            ...massiveApproveForm,
            ...defaultMassiveApproveForm
        });
    };

    const changeApprove = (
        value: string,
        massiveChange: boolean,
        setSelected: (status: string) => void
    ) => {
        if (value === '') {
            setSelected('');
            return;
        }

        const _status = tollApproveOptions.find((status: StatusRows) => status.value == value);

        const _text = `Está a punto de ${_status?.label} ${
            massiveChange ? 'todos los registros ' : 'los registros marcados'
        } de gastos`;

        useReactConfirmAlert().requestConfirmation({
            title: '¿Estás seguro?',
            message: _text,
            buttons: {
                confirmButton: {
                    label: 'Si, confirmar',
                    onClick: () => {
                        if (showLoading) showLoading('loading', 'Cambiando estados...');

                        changeApproveRowsFromTransportOrder(
                            {
                                ...massiveApproveForm,
                                approve_status: value,
                                all_selected: massiveChange
                            },
                            {
                                onSuccess: (response: ServiceResponse) => {
                                    setSelected(value);
                                    if (hideLoading) hideLoading();

                                    useReactConfirmAlert().successAlert({
                                        title: 'Éxito',
                                        message: response.message,
                                        message_list: response.data?.message_list ?? []
                                    });
                                    resetContextActions();
                                    if (callbackSuccess) callbackSuccess();
                                },
                                onError: (response: ServiceResponse) => {
                                    if (hideLoading) hideLoading();

                                    useReactConfirmAlert().errorAlert({
                                        title: 'Error',
                                        message: response.message
                                    });
                                }
                            }
                        );
                    }
                },
                cancelButton: {
                    label: 'No, cancelar',
                    onClick: () => {
                        setTimeout(() => {
                            useReactConfirmAlert().infoAlert({
                                title: 'Cancelado',
                                message: 'Los estados de gastos no se han cambiado.'
                            });
                        }, 0);
                    }
                }
            }
        });
    };
    const rowClick = (row: any) => {
        setExpenseUrlFile(row.photo);
    }

    const conditionalRowStylesClick = [
       {
          when: (row : any ) => row.photo === expenseUrlFile,  // Condición para resaltar la fila
          style: {
            backgroundColor: '#13395E',  // Estilo de fondo para la fila seleccionada
            color: 'white',
            '&:hover': {
              cursor: 'pointer',
            },
          },
        },
    ];
    const removeImage = () => {
        setExpenseUrlFile(undefined); // Limpiar el estado de la imagen

    };

    const contextActions = useMemo(() => {
        return (
            <ChangeStatusRows
                changeStatus={changeApprove}
                className="form-select-sm"
                statuses={tollApproveOptions}
                default_option={'Seleccione acción'}
            />
        );
    }, [expenses, rows, toggleCleared]);

    const handleRowSelected = useCallback(({ allSelected, selectedCount, selectedRows }: any) => {
        setRows(selectedRows);
        setMassiveApproveFormForm({
            ...massiveApproveForm,
            all_selected: false,
            selected_count: selectedCount,
            rows_ids: selectedRows.map((row: any) => row.id)
        });
    }, []);

    const { isDesktop } = useScreenDetector();

    return (
        <>
            <div className="row mt-3">
                <div className="col">
                    <ExpenseFilter
                        filter={filter}
                        setFilter={setFilter}
                        showTransportOrder={expensePage}
                        onSearch={onSearch}
                        expenseTypeData={expensesType}
                    />
                </div>
                {isDesktop && (
                    <div className="col-auto" style={{ paddingTop: 22 }}>
                        <button className="btn btn-success btn-sm" onClick={downloadExcel}>
                            <i className="fa fa-file-excel" /> Descargar
                        </button>
                    </div>
                )}
            </div>
            {canBeEdited && (
                <div className="row mb-2">
                    {/*<div className="col-auto" style={{ paddingTop: 22 }}>*/}
                    {/*    Cambio masivo estado*/}
                    {/*    <ChangeStatusRows*/}
                    {/*        changeStatus={changeApprove}*/}
                    {/*        className="form-select-sm"*/}
                    {/*        statuses={tollApproveOptions}*/}
                    {/*        default_option={"Seleccione acción"}*/}
                    {/*        massive_change={true}*/}
                    {/*    />*/}
                    {/*</div>*/}
                </div>
            )}
            <div className="row mt-3">

                {
                    expenseUrlFile &&
                    (<PreviewImageTable image={expenseUrlFile} close={removeImage} />)


                }
                <div className={`col-12 col-md-${expenseUrlFile?8:12} order-2 order-md-1`}>

            <DefaultDataTable
                title=" "
                columns={columns}
                data={expenses}
                progressPending={loading}
                isMobileStacked={true}
                paginationTotalRows={totalRows}
                filter={filter}
                setFilter={setFilter}
                selectableRows={canBeEdited && !auth?.roles?.includes('Conductor')}
                onSelectedRowsChange={handleRowSelected}
                selectableRowsHighlight={true}
                clearSelectedRows={toggleCleared}
                contextActions={contextActions}
                resetContextActions={resetContextActions}
                contextMessage={contextMessage({
                    selectedCount: massiveApproveForm.selected_count ?? 0,
                    allSelected: massiveApproveForm.all_selected!
                })}
                onRowClick={rowClick}
                conditionalRowStyles={conditionalRowStylesClick}
            />
                </div>
                </div>

        </>
    );
};

export default ExpenseDataTable;
