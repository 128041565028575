import React, { useState, useEffect, useContext } from 'react'
import DefaultCard from '../../../components/default/DefaultCard'
import DocumentsDataTable from '../../../components/DocumentsDataTable'
import useSocietyDocumentService from '../../../app/services/hooks/useSocietyDocumentService';
import { DocumentNameFilters } from '../../../app/models/Document';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import DefaultModal from '../../../components/default/DefaultModal';
import SocietyDocumentCreate from './SocietyDocumentCreate';
import SocietyDocumentHistory from './SocietyDocumentHistory';
import { AuthContext } from '../../../contexts/AuthContext';
import SocietyDocumentEdit from './SocietyDocumentEdit';

interface Props {
    societyId: number;
    isShow?: boolean;
}

const SocietyDocumentContainer = ({ societyId, isShow = false }: Props) => {

    const history = useHistory();
    const { auth } = useContext(AuthContext);
    const { getSocietyDocumentsBySociety, fetchingGetSocietyDocuments } = useSocietyDocumentService();

    const [documentTypes, setDocumentTypes] = useState<any[]>([]);
    const [typeDocumentSelected, setTypeDocumentSelected] = useState<any>();
    const [showingCreateDocument, setShowingCreateDocument] = useState(false);
    const [showingHistoryDocument, setShowingHistoryDocument] = useState(false);
    const [showingEditDocument, setShowingEditDocument] = useState(false);
    const [filter, setFilter] = useState<DocumentNameFilters>({
        document_name: ''
    })

    useEffect(() => {
        reloadTable();
    }, []);
    
    const reloadTable = () => {
        getDocuments(societyId);
    }

    const getDocuments = (id: number) => {
        getSocietyDocumentsBySociety(id, filter, {
            onSuccess: (response: ServiceResponse) => {
                setDocumentTypes(response.data.society_documents);
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message);
                if (response.data.to_dashboard) {
                    history.goBack();
                }
            }
        });
    };
    

    const showCreateDocument = (type: string) => {
        setTypeDocumentSelected(type);
        setShowingCreateDocument(true);
    };

    const hideCreateDocument = () => {
        setShowingCreateDocument(false);
    };

    const showHistoryDocument = (type: string) => {
        setTypeDocumentSelected(type);
        setShowingHistoryDocument(true);
    };

    const hideHistoryDocument = () => {
        setShowingHistoryDocument(false);
    };

    const showEditDocument = (type: string) => {
        setTypeDocumentSelected(type);
        setShowingEditDocument(true);
    };

    const hideEditDocument = () => {
        setShowingEditDocument(false);
    };

    return (
        <div>
            {showingCreateDocument ? (
                <DefaultModal
                    show={showingCreateDocument}
                    handleClose={hideCreateDocument}
                    title="Agregar Documento"
                    backdrop={true}
                    showFooter={false}
                >
                    <SocietyDocumentCreate
                        onSaved={() => {
                            reloadTable();
                            hideCreateDocument();
                        }}
                        typeDocument={typeDocumentSelected}
                        societyId={societyId}
                        onCancel={hideCreateDocument}
                        onError={hideCreateDocument}
                    />
                </DefaultModal>
            ) : null}

            {showingEditDocument ? (
                <DefaultModal
                    show={showingEditDocument}
                    handleClose={hideEditDocument}
                    title="Editar Fechas del Documento"
                    backdrop={true}
                    showFooter={false}
                >
                    <SocietyDocumentEdit
                        onSaved={() => {
                            hideEditDocument();
                            reloadTable();
                        }}
                        typeDocument={typeDocumentSelected}
                        societyId={societyId}
                        onCancel={hideEditDocument}
                        onError={hideEditDocument}
                    />
                </DefaultModal>
            ) : null}

            {showingHistoryDocument ? (
                <DefaultModal
                    show={showingHistoryDocument}
                    handleClose={hideHistoryDocument}
                    title={`Historial de documentos de ${
                        documentTypes?.find(
                            (document_type: any) =>
                                document_type.id === typeDocumentSelected
                        ).document_name
                    }`}
                    backdrop={true}
                    size={'lg'}
                    showFooter={false}
                >
                    <SocietyDocumentHistory
                        typeDocument={typeDocumentSelected}
                        societyId={societyId}
                        onCancel={hideHistoryDocument}
                        onError={hideHistoryDocument}
                    />
                </DefaultModal>
            ) : null}

            <div className="row">
                <div className="col-md-12">
                    <DefaultCard>
                        <div className="h3 mt-0 mb-2 card-title">
                            Documentos
                        </div>
                        <DocumentsDataTable
                            data={documentTypes}
                            create={showCreateDocument}
                            edit={auth?.permissions?.includes('change_societydocument') ? showEditDocument : undefined}
                            showHistory={showHistoryDocument}
                            progressPending={fetchingGetSocietyDocuments}
                            filter={filter}
                            setFilter={setFilter}
                            onSearch={reloadTable}
                        />
                    </DefaultCard>
                </div>
            </div>
        </div>

    )
}

export default SocietyDocumentContainer
