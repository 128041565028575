import React, { useContext } from 'react';
import { NotificationContext } from '../contexts/NotificationContext';
import DefaultModal from './default/DefaultModal';

const NotificationModal = () => {
    const { modalVisible, modalType, modalMessage, closeModal } = useContext(NotificationContext);

    return (
        <DefaultModal
            title={modalType === 'warning' ? 'Advertencia' : 'Acción requerida'}
            show={modalVisible}
            handleClose={modalType === 'warning' ? closeModal : undefined}
            showCancel={modalType === 'warning'}
            showFooter
            // actionButton={
            //     <button className="btn btn-primary" disabled>
                    
            //     </button>
            // }
        >
            {/* modalMessage es html */}
            <div dangerouslySetInnerHTML={{ __html: modalMessage }}></div>
        </DefaultModal>
    );
};

export default NotificationModal;
