import React, { useContext } from 'react';
import { InternalSettlementFilters } from '../../../../app/models/InternalSettlement';
import { AuthContext } from '../../../../contexts/AuthContext';
import ButtonSearchFilter from '../../../../components/buttons/ButtonSearchFilter';

type Props = {
    filter: InternalSettlementFilters;
    setFilter: (filter: InternalSettlementFilters) => void;
    onSearch: () => void;
};

const InternalSettlementFilter = ({ filter, setFilter, onSearch }: Props) => {
    const { auth } = useContext(AuthContext);

    const handlerInputFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFilter({
            ...filter,
            [e.target.name]: e.target.value
        });
    };

    const handlerSelectFilter = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setFilter({
            ...filter,
            [e.target.name]: e.target.value
        });
    };

    const handleCheckChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, checked } = event.target;
        setFilter({
            ...filter,
            [name]: checked
        });
    };

    return (
        <>
            <div className="row justify-content-start">
                <div className="col-lg-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="issue_date_from">
                            Fecha de emisión (desde)
                        </label>
                        <input
                            title="Fecha de emisión"
                            type="date"
                            id="issue_date_from"
                            name="issue_date_from"
                            className="form-control form-control-sm"
                            value={filter.issue_date_from}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>
                <div className="col-lg-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="issue_date_until">
                            Fecha de emisión (hasta)
                        </label>
                        <input
                            title="Fecha de emisión"
                            type="date"
                            id="issue_date_until"
                            name="issue_date_until"
                            className="form-control form-control-sm"
                            value={filter.issue_date_until}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>

                {(auth?.hasAllPermissions || !auth?.roles?.includes('Conductor')) && (
                    <div className="col-lg-auto">
                        <div className="mb-3">
                            <label className="font-size-10 mb-1" htmlFor="carrier">
                                Conductor
                            </label>
                            <input
                                title="Conductor"
                                type="text"
                                id="carrier"
                                name="carrier"
                                className="form-control form-control-sm"
                                value={filter.carrier}
                                onChange={(e) => handlerInputFilter(e)}
                            />
                        </div>
                    </div>
                )}
                <div className="col-lg-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="movement_type">
                            Tipo de Movimiento
                        </label>
                        <select
                            id="movement_type"
                            name="movement_type"
                            className="form-control form-select-sm"
                            value={filter.movement_type ?? ''}
                            onChange={(e) => handlerSelectFilter(e)}
                        >
                            <option key={''} value="">
                                Todos
                            </option>
                            <option key={'CREDIT'} value="CREDIT">
                                Cargo
                            </option>
                            <option key={'DEBIT'} value="DEBIT">
                                Abono
                            </option>
                        </select>
                    </div>
                </div>
                <div className="col-lg-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="expense">
                            Id Gasto
                        </label>
                        <input
                            title="Gasto"
                            type="number"
                            id="expense"
                            name="expense"
                            className="form-control form-control-sm"
                            value={filter.expense}
                            onChange={(e) => handlerInputFilter(e)}
                        />
                    </div>
                </div>
            {/*   sin viatico ni transporte */}
                <div className="col-lg-auto">
                    <div className="mb-3">
                        <label className="font-size-10 mb-1" htmlFor="without_viatic_transport">
                            Solo fondos por rendir
                        </label>
                        <div className="form-check form-switch">
                        <input
                            title="Solo fondos por rendir"
                            type="checkbox"
                            id="without_viatic_transport"
                            name="without_viatic_transport"
                            className="form-check-input"
                            checked={filter.without_viatic_transport}
                            onChange={handleCheckChange}
                        />
                        </div>
                    </div>
                </div>
                <div className='col-md-auto'>
                    <div style={{marginTop: '22px'}}>
                        <ButtonSearchFilter callbackSearch={onSearch}/>
                    </div>
                </div>
            </div>
        </>
    );
};

export default InternalSettlementFilter;
