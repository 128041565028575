import { Provider } from './Provider';
import { ExpenseType } from './ExpenseType';
import { ExpenseDocumentType } from './ExpenseDocumentType';
import moment from 'moment';
import { Currency } from './Currency';

export interface Expense {
    id?: number;
    expense_type?: ExpenseType;
    expense_type_id?: number;
    event_id?: number;
    currency?: Currency;
    currency_id?: number;

    amount?: number;
    provider_id?: number;
    provider?: Provider;
    issue_date: Date;
    expense_document_type?: string;
    expense_document_type_selected?: ExpenseDocumentType;
    photo?: File;
    created_user_id?: number;
    name?: string;
    folio?: string;
    description?: string;
    is_reported?: boolean;
}

export const defaultExpenseDataForm: ExpenseDataForm = {
    expense_type_id: 0,
    amount: '',
    provider_id: 0,
    issue_date: moment().format('YYYY-MM-dddd'),
    expense_document_type: '',
    currency_id: 0,
    folio: '',
    description: '',
    is_reported: true
};

export interface ExpenseDataForm {
    transport_order_id?: number;
    id?: number;
    currency?: Currency;
    carrier?: string;
    currency_id?: number;
    expense_type?: string;
    expense_type_id?: number;
    event_id?: number;
    amount?: number | string;
    provider_id?: number;
    provider?: string;
    issue_date?: string;
    expense_document_type?: string;
    description?: string;
    photo?: File;
    photo_url?: string;
    folio?: string;
    is_reported?: boolean;
    is_approved?: boolean;
}

export interface ExpenseFilters {
    id?: number | '';
    transport_order_id?: string | null;
    expense_id?: number | null;
    provider?: string | null;
    expense_type?: string | null;
    issue_date_first?: string | '';
    issue_date_last?: string | '';
    expense_document_type?: string | null;
    carrier?: string | null;
    page: number;
    per_page: number;
    sort: string;
    order: string;
}

export interface ExpenseDataShow {
    transport_order_id?: number;
    id?: number;
    currency?: string;
    carrier?: string;
    expense_type?: string;
    amount?: number;
    provider?: string;
    issue_date?: string;
    expense_document_type?: string;
    description?: string;
    photo_url?: string;
    folio?: string;
    is_reported?: boolean;
}
