import React, { useEffect, useState } from 'react';
import {  QuestionDataForm } from '../../../../../../app/models/Question';
import SelectTwo, { SelectTwoMapperOptions } from '../../../../../../components/SelectTwo';
import { ServiceResponse } from '../../../../../../app/services/shared/interfaces';
import { toast } from 'react-toastify';
import LazyLoading from '../../../../../../components/LazyLoading';
import { TruckDataForm } from '../../../../../../app/models/Truck';
import useHopperService from '../../../../../../app/services/hooks/useHopperService';

type Props = {
    input: QuestionDataForm; 
    handleChange: (user:any) => void;
    disabled?: boolean
};

const QuestionHopperField = ({ input, handleChange, disabled=false }: Props) => {

    const { allHoppers, fetchingAllHoppers } = useHopperService();

    const [hoppers, setHoppers] = useState<TruckDataForm[]>([]);

    useEffect(() => {
        getHoppers();
    }, []);

    const getHoppers = () => {
        allHoppers({
            onSuccess: (response: ServiceResponse) => {
                setHoppers(response.data.hoppers);
            },
            onError: (response: ServiceResponse) => {
                toast.error(response.message);
            }
        });
    };
    

    return fetchingAllHoppers ? (
        <LazyLoading height={300} />
    ) : (
        <div className="mb-5">
            <SelectTwo
                name={'hopper'}
                id={`${input.id}`}
                inputValue={input.text}
                options={SelectTwoMapperOptions(hoppers)}
                onChange={handleChange}
                placeholder={'Seleccione Semi Remolque'}
                isClearable={true}
                disabled={disabled}
            />
        </div>
    );
};

export default QuestionHopperField;