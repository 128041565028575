import { Product } from './Product';
import { Unit } from './Unit';
import { TransportOrder } from './TransportOrder';
import moment from 'moment/moment';
import { ExpenseDataForm } from './Expense';

export interface Guide {
    folio?: string;

    issue_date?: string;

    truck_id?: number;

    carrier_id?: number;
    worker_id?: number;
    hopper_id?: number;

    origin_id?: number;

    destiny_id?: number;

    product_id?: number;

    product?: Product;

    quantity?: number;

    unit_id?: number;

    unit?: Unit;

    transport_order_id?: number;

    transport_order?: TransportOrder;

    file?: File;
}

export interface GuideDataForm {
    id?: number;
    folio?: string;

    issue_date?: string;
    worker_id?: number;

    truck_id?: number;

    carrier_id?: number;

    hopper_id?: number;

    origin_id?: number;

    destiny_id?: number;

    product_id?: number;

    product?: Product;

    quantity?: number;

    unit_id?: number;

    unit?: string;

    transport_order_id?: number;

    transport_order?: TransportOrder;

    file?: File;
    url_file?: string;
    weighing_ticket?: File;
    url_weighing_ticket?: string;
    society?: string;
    carrier?: string;
    truck?: string;
    transporter?: string;
    client?: string;
    is_approved?: boolean;
    comment?: string;
    repeat_folio_number?: boolean
}

export const defaultGuideDataForm: GuideDataForm = {
    folio: '',
    issue_date: '',
    repeat_folio_number: false
};

export interface GuideFilters {
    id?: number | null;
    page?: number;
    per_page?: number;
    sort?: string;
    order?: string;
    transport_order_id?: string | null;
    folio?: string | '';
    issue_date?: string | '';
    society?: string | '';
    client?: string | '';
    transporter?: string | '';
    carrier?: string | '';
    truck?: string | '';
}
