import useFetch from '../../../hooks/useFetch';
import { ServiceEvents, ServiceEventsDocuments, ServiceResponse } from '../shared/interfaces';
import { useContext, useState } from 'react';
import { EndPoints } from '../EndPoints';
import { FuelConsumptionFilters } from '../../models/FuelConsumption';
import { fetchDefaultHeaders } from '../shared/vars';
import { AppContext } from '../../../contexts/AppContext';
import { toast } from 'react-toastify';

const useFuelConsumptionService = () => {
    const { doGet, doPost, doPut, doDelete, doGetDocument } = useFetch();

    const [fetchingGetFuelConsumptions, setFetchingGetFuelConsumptions] = useState(false);
    const [fetchingGetFuelConsumptionsByTruck, setFetchingGetFuelConsumptionsByTruck] = useState(false);
    const [fetchingGetExcelFuelConsumptions, setFetchingGetExcelFuelConsumptions] = useState(false);
    const [fetchingShowFuelConsumption, setFetchingShowFuelConsumption] = useState(false);
    const [fetchingStoreFuelConsumption, setFetchingStoreFuelConsumption] = useState(false);
    const [fetchingEditFuelConsumption, setFetchingEditFuelConsumption] = useState(false);
    const [fetchingUpdateFuelConsumption, setFetchingUpdateFuelConsumption] = useState(false);
    const [fetchingDeleteFuelConsumption, setFetchingDeleteFuelConsumption] = useState(false);
    const [fetchingActiveFuelConsumption, setFetchingActiveFuelConsumption] = useState(false);

    const getFuelConsumptions = (
        fuelConsumptionFilters: FuelConsumptionFilters,
        events: ServiceEvents = {}
    ) => {
        const queryString = Object.entries(fuelConsumptionFilters)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        const url = `${EndPoints.FUEL_CONSUMPTIONS.GET_FUEL_CONSUMPTIONS}?${queryString}`;
        doGet({
            ...events,
            url: url,
            setFetching: setFetchingGetFuelConsumptions
        });
    };

    const getFuelConsumptionsByTruck = (
        fuelConsumptionFilters: FuelConsumptionFilters,
        truckId: number,
        events: ServiceEvents = {}
    ) => {
        const queryString = Object.entries(fuelConsumptionFilters)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        const url = `${EndPoints.FUEL_CONSUMPTIONS.GET_FUEL_CONSUMPTIONS_BY_TRUCK.replace(
            ':id',
            truckId.toString()
        )}?${queryString}`;
        doGet({
            ...events,
            url: url,
            setFetching: setFetchingGetFuelConsumptionsByTruck
        });
    };

    // const getExcelAllFuelConsumptions = (fuelConsumptionFilters: FuelConsumptionFilters, events: ServiceEvents = {}) => {

    //     const queryString = Object.entries(fuelConsumptionFilters)
    //         .map(([key, value]) => `${key}=${value}`)
    //         .join('&');

    //     const url = `${EndPoints.FUEL_CONSUMPTIONS.GET_EXCEL_FUEL_CONSUMPTIONS}?${queryString}`;
    //     doGet({
    //         ...events,
    //         url: url,
    //         setFetching: setFetchingGetExcelFuelConsumptions
    //     });

    // };

    // const downloadDocumentExcel = (response:any) => {

    //     response.blob().then((blob:any) => {
    //         const a = document.createElement('a');
    //         const url = window.URL.createObjectURL(blob);
    //         a.href = url;
    //         a.download = `consumo_combustible.xlsx`;
    //         a.click();
    //         window.URL.revokeObjectURL(url);

    //     }).catch((error:any) => {
    //         console.log(error)
    //         toast.error("Descarga fallida")

    //     });
    // };

    const getExcelAllFuelConsumptionsByTruck = (
        fuelConsumptionFilters: FuelConsumptionFilters,
        truckId: number,
        events: ServiceEventsDocuments = {}
    ) => {
        // const {showLoading, hideLoading, changeAnimation} = useContext(AppContext);
        const queryString = Object.entries(fuelConsumptionFilters)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        const url = `${EndPoints.FUEL_CONSUMPTIONS.GET_EXCEL_FUEL_CONSUMPTIONS_BY_TRUCK.replace(
            ':id',
            truckId.toString()
        )}?${queryString}`;

        doGetDocument({
            ...events,
            url: url,
            successData: {
                nameDocument: 'consumo_combustible.xlsx',
                message: 'Descarga exitosa.'
            },
            setFetching: setFetchingGetFuelConsumptions
        });
    };

    const getExcelAllFuelConsumptions = (
        fuelConsumptionFilters: FuelConsumptionFilters,
        events: ServiceEventsDocuments = {}
    ) => {
        // const {showLoading, hideLoading, changeAnimation} = useContext(AppContext);
        const queryString = Object.entries(fuelConsumptionFilters)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');

        const url = `${EndPoints.FUEL_CONSUMPTIONS.GET_EXCEL_FUEL_CONSUMPTIONS}?${queryString}`;

        doGetDocument({
            ...events,
            url: url,
            successData: {
                nameDocument: 'consumo_combustible.xlsx',
                message: 'Descarga exitosa.'
            },
            setFetching: setFetchingGetFuelConsumptions
        });
    };

    return {
        fetchingGetFuelConsumptions,
        fetchingGetExcelFuelConsumptions,
        fetchingGetFuelConsumptionsByTruck,
        getFuelConsumptionsByTruck,
        getFuelConsumptions,
        getExcelAllFuelConsumptions,
        getExcelAllFuelConsumptionsByTruck
        // downloadDocumentExcel
    };
};

export default useFuelConsumptionService;
