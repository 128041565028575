import React, { createContext, useContext, useEffect, useReducer, useState } from 'react';
import { NotificationContextStateInterface } from './interfaces';
import reducerAuth from './reducer';
import { NOTIFICATIONS } from './types';
import { channel } from '../../App';
import useNotificationService from '../../app/services/hooks/useNotificationService';
import { ServiceResponse } from '../../app/services/shared/interfaces';
import { AuthContext } from '../AuthContext';
import NotificationModal from '../../components/NotificationModal';
import { use } from 'echarts';
import { useLocation } from "react-router-dom";

const initialState: NotificationContextStateInterface = {
    notifications: []
};

export const NotificationContext = createContext<NotificationContextStateInterface & {
    modalVisible: boolean;
    modalType: 'warning' | 'restrictive' | null;
    modalMessage: string;
    closeModal: () => void;
}>(initialState as any);

const NotificationProvider = ({ children }: { children: React.ReactNode }) => {
    const [state, dispatch] = useReducer(reducerAuth, initialState);
    const [modalVisible, setModalVisible] = useState(false);
    const [modalType, setModalType] = useState<'warning' | 'restrictive' | null>(null);
    const [modalMessage, setModalMessage] = useState('');
    const location = useLocation(); // Obtiene la URL actual
    const { getNotificationsNotViewed, checkPendingSurvey } = useNotificationService();
    const { auth } = useContext(AuthContext);

    useEffect(() => {
        if (auth) {
            getNotifications();
            checkSurveyStatus(); // Verifica si hay encuestas pendientes
            channel.bind('notify', function (data: any) {
                getNotifications();
                checkSurveyStatus(); // Vuelve a verificar cuando haya una notificación
            });
        }
    }, [auth]);

    useEffect(() => {
        checkSurveyStatus();
    }, [location?.pathname]);

    const getNotifications = () =>
        getNotificationsNotViewed({
            onSuccess: (response: ServiceResponse) => {
                dispatch({
                    type: NOTIFICATIONS,
                    payload: response.data.notifications
                });
            }
        });
        const checkSurveyStatus = () =>
            checkPendingSurvey({
                onSuccess: (response: ServiceResponse) => {
                    if (response.data.pending_surveys.length > 0) {
                        setModalVisible(true);
                        setModalType('warning');
        
                        let message_html = `
                            <p><strong>Usted tiene encuestas pendientes por responder:</strong></p>
                            <table style="width: 100%; border-collapse: collapse; text-align: left;">
                                <thead>
                                    <tr style="background-color: #f4f4f4; border-bottom: 2px solid #ddd;">
                                        <th style="padding: 10px; border-bottom: 1px solid #ddd;">Fecha</th>
                                        <th style="padding: 10px; border-bottom: 1px solid #ddd;">Encuesta</th>
                                        <th style="padding: 10px; border-bottom: 1px solid #ddd;">Acción</th>
                                    </tr>
                                </thead>
                                <tbody>
                        `;
        
                        response.data.pending_surveys.forEach((survey: any) => {
                            const eventId = survey.event_id || survey.parent_event_id;
                            const surveyName = survey.survey_name || "Encuesta desconocida";
        
                            const url = `/calendar-by-worker/${eventId}/event/show?start_date=${survey.date}&end_date=${survey.date}&admin=false&tab=checklists-data`;
        
                            message_html += `
                                <tr style="border-bottom: 1px solid #ddd;">
                                    <td style="padding: 10px;">${survey.date}</td>
                                    <td style="padding: 10px;">${surveyName}</td>
                                    <td style="padding: 10px;"><a href="${url}" style="color: #183540; text-decoration: none; font-weight: bold;">Responder</a></td>
                                </tr>
                            `;
                        });
        
                        message_html += `
                                </tbody>
                            </table>
                            <p style="margin-top: 10px;">
                                ⚠️ Por favor, responda las encuestas pendientes. Esta alerta se mostrará cada 1 hora hasta que complete todas las encuestas pendientes.
                            </p>
                        `;
        
                        setModalMessage(message_html);
                    }
                }
            });
        
        
        
        

    const closeModal = () => {
        if (modalType === 'warning') {
            setModalVisible(false);
        }
    };

    return (
        <NotificationContext.Provider
            value={{
                notifications: state.notifications,
                modalVisible,
                modalType,
                modalMessage,
                closeModal
            }}
        >
            {children}
            {modalVisible && <NotificationModal />}
        </NotificationContext.Provider>
    );
};

export default NotificationProvider;
