import React from 'react';
import { CheckingAccountFilters } from '../../../../app/models/CheckingAccount';
import ButtonSearchFilter from '../../../../components/buttons/ButtonSearchFilter';

type Props = {
    filter: CheckingAccountFilters;
    setFilter: (filter: CheckingAccountFilters) => void;
    onSearch: () => void;
};

const CheckingAccountFilter = ({ filter, setFilter, onSearch }: Props) => {
    const handlerInputFilter = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFilter({
            ...filter,
            [e.target.name]: e.target.value
        });
    };

    return (
        <div className="row">
            <div className="row">
                <div className="row">
                    <div className="col-md-auto">
                        <div className="mb-3">
                            <label className="font-size-10 mb-1" htmlFor="id">
                                Id transferencia
                            </label>
                            <input
                                title="id transferencia"
                                type="number"
                                id="id"
                                name="id"
                                className="form-control form-control-sm"
                                value={filter.id}
                                onChange={(e) => handlerInputFilter(e)}
                            />
                        </div>
                    </div>
                    <div className="col-md-auto">
                        <div className="mb-3">
                            <label className="font-size-10 mb-1" htmlFor="issue_date_first">
                                Fecha de emisión (desde)
                            </label>
                            <input
                                title="Fecha de emisión"
                                type="date"
                                id="issue_date_first"
                                name="issue_date_first"
                                className="form-control form-control-sm"
                                value={filter.issue_date_first}
                                onChange={(e) => handlerInputFilter(e)}
                            />
                        </div>
                    </div>
                    <div className="col-md-auto">
                        <div className="mb-3">
                            <label className="font-size-10 mb-1" htmlFor="issue_date_last">
                                Fecha de emisión (hasta)
                            </label>
                            <input
                                title="Fecha de emisión"
                                type="date"
                                id="issue_date_last"
                                name="issue_date_last"
                                className="form-control form-control-sm"
                                value={filter.issue_date_last}
                                onChange={(e) => handlerInputFilter(e)}
                            />
                        </div>
                    </div>
                    <div className="col-md-auto">
                        <div className="mb-3">
                            <label className="font-size-10 mb-1" htmlFor="carrier">
                                Conductor
                            </label>
                            <input
                                title="Conductor"
                                type="text"
                                id="carrier"
                                name="carrier"
                                className="form-control form-control-sm"
                                value={filter.carrier}
                                onChange={(e) => handlerInputFilter(e)}
                            />
                        </div>
                    </div>
                    <div className="col-md-auto">
                        <div className="mb-3">
                            <label className="font-size-10 mb-1" htmlFor="transfer_reason">
                                Motivo
                            </label>
                            <input
                                title="Motivo"
                                type="text"
                                id="transfer_reason"
                                name="transfer_reason"
                                className="form-control form-control-sm"
                                value={filter.transfer_reason}
                                onChange={handlerInputFilter}
                            />
                        </div>
                    </div>
                    <div className='col-md-auto'>
                        <div style={{marginTop: '22px'}}>
                            <ButtonSearchFilter callbackSearch={onSearch}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CheckingAccountFilter;
