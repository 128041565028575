import React, { useContext, useState, useEffect } from 'react';
import { AppContext } from '../../../contexts/AppContext';
import useReactConfirmAlert from '../../../hooks/useReactConfirmAlert';
import { ServiceResponse } from '../../../app/services/shared/interfaces';
import { toast } from 'react-toastify';
import ButtonCancelForm from '../../../components/buttons/ButtonCancelForm';
import ButtonSaveForm from '../../../components/buttons/ButtonSaveForm';
import GeneralDocumentForm from '../../../components/GeneralDocumentForm';
import useTruckDocumentService from '../../../app/services/hooks/useTruckDocumentService';
import { TruckDocument, defaultTruckDocument } from '../../../app/models/Truck';

interface Props {
    onSaved?: () => void;
    onCancel?: () => void;
    onError?: () => void;
    truckId: number;
    typeDocument: string;
}

const TruckDocumentEdit = ({ onSaved, onCancel, onError, typeDocument, truckId }: Props) => {

    const { showLoading, hideLoading, changeAnimation } = useContext(AppContext);

    const [truckDocument, setTruckDocument] = useState<TruckDocument>(defaultTruckDocument);
    const [errorFields, setErrorFields] = useState<any>();
    const { fetchingEditTruckDocument,
        fetchingUpdateTruckDocument,
        editTruckDocument,
        updateTruckDocument } = useTruckDocumentService();

    useEffect(() => {
        edit();
    }, []);

    const edit = () => {
        if (showLoading) showLoading('loading', 'Guardando documento...');
        editTruckDocument(truckId, parseInt(typeDocument), {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setTruckDocument(response.data.truck_document)
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    const update = () => {
        if (!truckDocument) return;
        if (showLoading) showLoading('loading', 'Guardando documento...');
        updateTruckDocument(truckDocument.id!, truckDocument, {
            onSuccess: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().successAlert({
                    title: 'Éxito',
                    message: response.message
                });
                toast.success(response.message, {
                    autoClose: 2500
                });
                if (onSaved) onSaved();
            },
            onError: (response: ServiceResponse) => {
                if (hideLoading) hideLoading();
                useReactConfirmAlert().errorAlert({
                    title: 'Error',
                    message: response.message
                });
            },
            onFieldError: (errorFields: ServiceResponse) => {
                if (hideLoading) hideLoading();
                setErrorFields(errorFields.data);
            }
        });
    };

    const cancel = () => {
        if (onCancel) onCancel();
    };

    return (
        <>
            <div className="row">
                <div className="col-12">
                    <GeneralDocumentForm
                        document={truckDocument}
                        setDocument={setTruckDocument}
                        errorFields={errorFields}
                        edit={true}
                    />
                </div>
            </div>
            <hr />
            <div className="row justify-content-end">
                <div className="col-auto">
                    <ButtonCancelForm
                        callbackCancel={cancel}
                        locked={fetchingUpdateTruckDocument}
                    />
                </div>
                <div className="col-auto">
                    <ButtonSaveForm 
                        callbackSave={update} 
                        locked={fetchingUpdateTruckDocument} 
                    />
                </div>
            </div>
        </>
    );
};

export default TruckDocumentEdit;
