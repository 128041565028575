import useFetch from '../../../hooks/useFetch';
import { useState } from 'react';
import { ServiceEvents, ServiceEventsDocuments } from '../shared/interfaces';
import { EndPoints } from '../EndPoints';
import { MonthlyClosureDetail, MonthlyClosureDetailDataForm, MonthlyClosureDetailFilters } from '../../models/MonthlyClosureDetail';

const useMonthlyClosureDetailService = () => {

    const { doGet, doPost, doPut, doDelete } = useFetch();

    const [fetchingGetMonthlyClosureDetailsByClosure, setFetchingGetMonthlyClosureDetailsByClosure] =
        useState(false);
    const [fetchingCreateMonthlyClosureDetail, setFetchingCreateMonthlyClosureDetail] =
        useState(false);
    const [fetchingStoreMonthlyClosureDetail, setFetchingStoreMonthlyClosureDetail] =
        useState(false);
    const [fetchingCanChangeMonthlyClosureDetailStatus, setFetchingCanChangeMonthlyClosureDetailStatus] =
        useState(false);
    const [fetchingChangeMonthlyClosureDetailStatus, setFetchingChangeMonthlyClosureDetailStatus] =
        useState(false);


    const getMonthlyClosureDetailsByClosure = (
        closureId: number, 
        filter: MonthlyClosureDetailFilters, 
        events: ServiceEvents = {}
    ) => {
        const queryString = Object.entries(filter)
            .map(([key, value]) => `${key}=${value}`)
            .join('&');
        const url = `${EndPoints.MONTHLY_CLOSURE_DETAILS.ALL_MONTHLY_CLOSURE_DETAILS_BY_CLOSURE.replace(
            ':monthly_closure_id',
            closureId.toString()
        )}?${queryString}`;
    
        doGet({
            ...events,
            url: url,
            setFetching: setFetchingGetMonthlyClosureDetailsByClosure
        });
    };

    const createMonthlyClosureDetail = (
        monthly_closure_id: number,
        events: ServiceEvents = {}
    ) => {
        doGet({
            ...events,
            url: EndPoints.MONTHLY_CLOSURE_DETAILS.CREATE_MONTHLY_CLOSURE_DETAIL
            .replace(':monthly_closure_id', monthly_closure_id.toString()),
            setFetching: setFetchingCreateMonthlyClosureDetail
        });
    };

    const storeMonthlyClosureDetail = (
        MonthlyClosureDetailDataForm: MonthlyClosureDetailDataForm, 
        events: ServiceEvents = {}
    ) => {
        doPost({
            ...events,
            url: EndPoints.MONTHLY_CLOSURE_DETAILS.STORE_MONTHLY_CLOSURE_DETAIL,
            body: MonthlyClosureDetailDataForm,
            setFetching: setFetchingStoreMonthlyClosureDetail
        });
    };

    const canChangeMonthlyClosureDetailsStatus = (
        id: number, 
        status: string,
        events: ServiceEvents = {}
    ) => {
        doGet({
            ...events,
            url: EndPoints.MONTHLY_CLOSURE_DETAILS.CAN_CHANGE_MONTHLY_CLOSURE_DETAIL_STATUS
            .replace(':id', id.toString())
            .replace(':status', status),
            setFetching: setFetchingCanChangeMonthlyClosureDetailStatus
        });
    };

    const changeMonthlyClosureDetailsStatus = (
        id: number, 
        MonthlyClosureDetail: MonthlyClosureDetail, 
        events: ServiceEvents = {}
    ) => {
        doPut({
            ...events,
            url: EndPoints.MONTHLY_CLOSURE_DETAILS.CHANGE_MONTHLY_CLOSURE_DETAIL_STATUS.replace(
                ':id',
                id.toString()
            ),
            body: MonthlyClosureDetail,
            setFetching: setFetchingChangeMonthlyClosureDetailStatus
        });
    };

    const closeMonthlyClosureDetailsStatus = (
        id: number, 
        MonthlyClosureDetail: MonthlyClosureDetail, 
        events: ServiceEvents = {}
    ) => {
        doPut({
            ...events,
            url: EndPoints.MONTHLY_CLOSURE_DETAILS.CLOSE_MONTHLY_CLOSURE_DETAIL_STATUS.replace(
                ':id',
                id.toString()
            ),
            body: MonthlyClosureDetail,
            setFetching: setFetchingChangeMonthlyClosureDetailStatus
        });
    };

    const annulledMonthlyClosureDetailsStatus = (
        id: number, 
        MonthlyClosureDetail: MonthlyClosureDetail, 
        events: ServiceEvents = {}
    ) => {
        doPut({
            ...events,
            url: EndPoints.MONTHLY_CLOSURE_DETAILS.ANNULLED_MONTHLY_CLOSURE_DETAIL_STATUS.replace(
                ':id',
                id.toString()
            ),
            body: MonthlyClosureDetail,
            setFetching: setFetchingChangeMonthlyClosureDetailStatus
        });
    };

    return {
        fetchingGetMonthlyClosureDetailsByClosure,
        fetchingCreateMonthlyClosureDetail,
        fetchingStoreMonthlyClosureDetail,
        fetchingCanChangeMonthlyClosureDetailStatus,
        fetchingChangeMonthlyClosureDetailStatus,
        getMonthlyClosureDetailsByClosure,
        createMonthlyClosureDetail,
        storeMonthlyClosureDetail,
        canChangeMonthlyClosureDetailsStatus,
        changeMonthlyClosureDetailsStatus,
        closeMonthlyClosureDetailsStatus,
        annulledMonthlyClosureDetailsStatus
    };
};

export default useMonthlyClosureDetailService;
